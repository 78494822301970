import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import projectService from '../../services/project-services/projects.service';

/**
 * NewContactModal Component
 * 
 * This component renders a modal to add new contacts to a project.
 * 
 * @param {Object} props - Component properties
 * @param {Function} props.setIsNewContactModalOpen - Function to toggle the modal's open state
 * @param {boolean} props.isNewContactModalOpen - Boolean indicating if the modal is open
 * @param {Function} props.fetchContacts - Function to refetch the contacts after a new one is added
 * @param {Object} props.toast - Toast notification instance
 */
export default function NewContactModal({ setIsNewContactModalOpen, isNewContactModalOpen, fetchContacts, toast }) {
  const cancelButtonRef = useRef(null);
  const [contactFirstName, setFirstName] = useState('');
  const [contactLastName, setLastName] = useState('');
  const [contactTitle, setTitle] = useState('');
  const [contactEmail, setEmail] = useState('');
  const [contactPhoneNumber, setPhoneNumber] = useState('');
  const [contactNotes, setContactNotes] = useState('');

  /**
   * Resets the form fields to their initial state.
   */
  const resetFormFields = () => {
    setFirstName('');
    setLastName('');
    setTitle('');
    setEmail('');
    setPhoneNumber('');
    setContactNotes('');
  };

  /**
   * Submits new contact information to the server.
   */
  const submitContactInfo = async () => {
    const data = {
      contact_first_name: contactFirstName,
      contact_last_name: contactLastName,
      contact_title: contactTitle,
      contact_email: contactEmail,
      contact_phone_number: contactPhoneNumber,
      contact_notes: contactNotes,
    };

    try {
      await projectService.newContact(data);
      fetchContacts();
      setIsNewContactModalOpen(false);
      toast.success('Contact added successfully!');
      resetFormFields();
    } catch (error) {
      console.error('Error adding new contact:', error);
      toast.error('Failed to add new contact.');
    }
  };

  return (
    <Transition.Root show={isNewContactModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" initialFocus={cancelButtonRef} onClose={setIsNewContactModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-xl sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      New Contact
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Feel free to add as many contacts as you need to each project!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-900/10 pb-12">
                  <div className="mt-10 mx-auto max-w-xl">
                    <label htmlFor="contact_first_name" className="block text-sm font-medium leading-6 text-gray-900">
                      Contact first name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="contact_first_name"
                        id="contact_first_name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactFirstName}
                        onChange={(evt) => setFirstName(evt.target.value)}
                      />
                    </div>

                    <label htmlFor="contact_last_name" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Contact last name
                    </label>
                    <div className="mt-4">
                      <input
                        type="text"
                        name="contact_last_name"
                        id="contact_last_name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactLastName}
                        onChange={(evt) => setLastName(evt.target.value)}
                      />
                    </div>

                    <label htmlFor="contact_title" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Contact title
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="contact_title"
                        id="contact_title"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactTitle}
                        onChange={(evt) => setTitle(evt.target.value)}
                      />
                    </div>

                    <label htmlFor="contact_email" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Contact email
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="contact_email"
                        id="contact_email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactEmail}
                        onChange={(evt) => setEmail(evt.target.value)}
                      />
                    </div>

                    <label htmlFor="contact_phone_number" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Contact phone number
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="contact_phone_number"
                        id="contact_phone_number"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactPhoneNumber}
                        onChange={(evt) => setPhoneNumber(evt.target.value)}
                      />
                    </div>

                    <label htmlFor="contact_notes" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                      Contact notes
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="contact_notes"
                        name="contact_notes"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                  ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 
                                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={contactNotes}
                        onChange={(evt) => setContactNotes(evt.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 ">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 
                              text-sm font-semibold text-white shadow-sm hover:bg-blue-700 
                              focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                              focus-visible:outline-blue-600 sm:col-start-2"
                    onClick={submitContactInfo}
                  >
                    {/* <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" /> */}
                    Add Contact
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm 
                              font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
                              hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsNewContactModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
