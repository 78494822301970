import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import projectService from '../../services/project-services/projects.service';
import useLoadingSpinner, { LoadingSpinnerOverlay } from '../LoadingSpinnerOverlay';

/**
 * UtilityInformationForm component to display and update utility information.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.project - Project object containing utility information.
 * @param {Function} props.fetchProject - Function to fetch the latest project data.
 * @returns {JSX.Element}
 */
export default function UtilityInformationForm({ project, fetchProject }) {
  const [account_id, setAccount] = useState('');
  const [utility_company, setUtilityCompany] = useState('');
  const [utility_account_holder, setUtilityAccountHolder] = useState('');
  const [utility_account_number, setUtilityAccountNumber] = useState('');
  const [meter_number, setMeterNumber] = useState('');
  const [number_of_meters, setNumMeters] = useState('');
  const [main_panel_amperage, setMainPanelAmperage] = useState('');
  const [power_bill_received, setBillReceivedValue] = useState('');
  const [january_kilowatt_amount, setJanKw] = useState('');
  const [february_kilowatt_amount, setFebKw] = useState('');
  const [march_kilowatt_amount, setMarKw] = useState('');
  const [april_kilowatt_amount, setAprKw] = useState('');
  const [may_kilowatt_amount, setMayKw] = useState('');
  const [june_kilowatt_amount, setJunKw] = useState('');
  const [july_kilowatt_amount, setJulKw] = useState('');
  const [august_kilowatt_amount, setAugKw] = useState('');
  const [september_kilowatt_amount, setSepKw] = useState('');
  const [october_kilowatt_amount, setOctKw] = useState('');
  const [november_kilowatt_amount, setNovKw] = useState('');
  const [december_kilowatt_amount, setDecKw] = useState('');
  const [average_monthly_power_bill_cost, setMonthlyPowerBill] = useState('');
  const [additional_utility_notes, setUtilityNotes] = useState('');
  const { isLoading, withMinimumSpinTime } = useLoadingSpinner();
  const [annual_kilowatt_amount, setAnnualKw] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAccount(project.account_id);
    setUtilityCompany(project.utility_company || '');
    setUtilityAccountHolder(project.utility_account_holder || '');
    setUtilityAccountNumber(project.utility_account_number || '');
    setMeterNumber(project.meter_number || '');
    setNumMeters(project.number_of_meters || '');
    setMainPanelAmperage(project.main_panel_amperage || '');
    setBillReceivedValue(project.power_bill_received || '');
    setJanKw(project.january_kilowatt_amount || '');
    setFebKw(project.february_kilowatt_amount || '');
    setMarKw(project.march_kilowatt_amount || '');
    setAprKw(project.april_kilowatt_amount || '');
    setMayKw(project.may_kilowatt_amount || '');
    setJunKw(project.june_kilowatt_amount || '');
    setJulKw(project.july_kilowatt_amount || '');
    setAugKw(project.august_kilowatt_amount || '');
    setSepKw(project.september_kilowatt_amount || '');
    setOctKw(project.october_kilowatt_amount || '');
    setNovKw(project.november_kilowatt_amount || '');
    setDecKw(project.december_kilowatt_amount || '');
    setMonthlyPowerBill(project.average_monthly_power_bill_cost || '');
    setUtilityNotes(project.additional_utility_notes || '');
  }, [project]);

  useEffect(() => {
    const total = [
      january_kilowatt_amount,
      february_kilowatt_amount,
      march_kilowatt_amount,
      april_kilowatt_amount,
      may_kilowatt_amount,
      june_kilowatt_amount,
      july_kilowatt_amount,
      august_kilowatt_amount,
      september_kilowatt_amount,
      october_kilowatt_amount,
      november_kilowatt_amount,
      december_kilowatt_amount,
    ].reduce((acc, curr) => acc + parseFloat(curr || 0), 0);
    setAnnualKw(total.toString());
  }, [
    january_kilowatt_amount,
    february_kilowatt_amount,
    march_kilowatt_amount,
    april_kilowatt_amount,
    may_kilowatt_amount,
    june_kilowatt_amount,
    july_kilowatt_amount,
    august_kilowatt_amount,
    september_kilowatt_amount,
    october_kilowatt_amount,
    november_kilowatt_amount,
    december_kilowatt_amount,
  ]);

  const submitUpdatedUtilityInfo = async (data) => {
    try {
      await projectService.updateProject(data);
      fetchProject();
      toast.success('Updated utility information successfully!');
    } catch (error) {
      console.error('Error updating utility info: ', error);
      toast.error('Error updating utility information!');
    }
  };

  const handleUpdatedUtilityInfo = (e) => {
    e.preventDefault();
    const data = {
      account_id,
      utility_company,
      utility_account_holder,
      utility_account_number,
      meter_number,
      number_of_meters,
      main_panel_amperage,
      power_bill_received,
      january_kilowatt_amount,
      february_kilowatt_amount,
      march_kilowatt_amount,
      april_kilowatt_amount,
      may_kilowatt_amount,
      june_kilowatt_amount,
      july_kilowatt_amount,
      august_kilowatt_amount,
      september_kilowatt_amount,
      october_kilowatt_amount,
      november_kilowatt_amount,
      december_kilowatt_amount,
      annual_kilowatt_amount,
      average_monthly_power_bill_cost,
      additional_utility_notes,
    };
    withMinimumSpinTime(() => submitUpdatedUtilityInfo(data));
  };

  return (
    <>
      {isLoading && <LoadingSpinnerOverlay />}

      <div className="flex justify-center mt-5">
        <div className="w-full max-w-7xl">
          <div className="rounded-lg bg-white px-4 py-4 shadow-lg border border-gray-100">
            <h2 className="text-2xl font-semibold text-gray-700 py-2">Utility Information</h2>
            <form onSubmit={handleUpdatedUtilityInfo} className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5">
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="utility_company" className="block text-sm font-medium text-gray-900">
                  Utility Company
                </label>
                <input
                  type="text"
                  name="utility_company"
                  id="utility_company"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={utility_company}
                  onChange={(evt) => setUtilityCompany(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="utility_account_holder" className="block text-sm font-medium text-gray-900">
                  Account Holder
                </label>
                <input
                  type="text"
                  name="utility_account_holder"
                  id="utility_account_holder"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={utility_account_holder}
                  onChange={(evt) => setUtilityAccountHolder(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="utility_account_number" className="block text-sm font-medium text-gray-900">
                  Account Number
                </label>
                <input
                  type="text"
                  name="utility_account_number"
                  id="utility_account_number"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={utility_account_number}
                  onChange={(evt) => setUtilityAccountNumber(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="meter_number" className="block text-sm font-medium text-gray-900">
                  Meter Number
                </label>
                <input
                  type="text"
                  name="meter_number"
                  id="meter_number"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={meter_number}
                  onChange={(evt) => setMeterNumber(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="number_of_meters" className="block text-sm font-medium text-gray-900">
                  Number of Meters
                </label>
                <select
                  id="number_of_meters"
                  name="number_of_meters"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={number_of_meters}
                  onChange={(evt) => setNumMeters(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="1 Meter">1 Meter</option>
                  <option value="2 Meters">2 Meters</option>
                  <option value="3 Meters">3 Meters</option>
                  <option value="4 Meters">4 Meters</option>
                  <option value="5+ Meters">5+ Meters</option>
                </select>
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="main_panel_amperage" className="block text-sm font-medium text-gray-900">
                  Main Panel Amperage
                </label>
                <select
                  id="main_panel_amperage"
                  name="main_panel_amperage"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={main_panel_amperage}
                  onChange={(evt) => setMainPanelAmperage(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="100 Amps">100 Amps</option>
                  <option value="200 Amps">200 Amps</option>
                  <option value="400 Amps">400 Amps</option>
                  <option value="600 Amps">600 Amps</option>
                  <option value="800 Amps">800 Amps</option>
                  <option value="1000+ Amps">1000+ Amps</option>
                </select>
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="power_bill_received" className="block text-sm font-medium text-gray-900">
                  Power Bill Received
                </label>
                <select
                  id="power_bill_received"
                  name="power_bill_received"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={power_bill_received}
                  onChange={(evt) => setBillReceivedValue(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </div>
              
              <div className="hidden sm:block ">
                {/* Placeholder Div */}
              </div>

              {power_bill_received !== '' && (
                <>
                  {[
                    ['january_kilowatt_amount', 'January', january_kilowatt_amount, setJanKw],
                    ['february_kilowatt_amount', 'February', february_kilowatt_amount, setFebKw],
                    ['march_kilowatt_amount', 'March', march_kilowatt_amount, setMarKw],
                    ['april_kilowatt_amount', 'April', april_kilowatt_amount, setAprKw],
                    ['may_kilowatt_amount', 'May', may_kilowatt_amount, setMayKw],
                    ['june_kilowatt_amount', 'June', june_kilowatt_amount, setJunKw],
                    ['july_kilowatt_amount', 'July', july_kilowatt_amount, setJulKw],
                    ['august_kilowatt_amount', 'August', august_kilowatt_amount, setAugKw],
                    ['september_kilowatt_amount', 'September', september_kilowatt_amount, setSepKw],
                    ['october_kilowatt_amount', 'October', october_kilowatt_amount, setOctKw],
                    ['november_kilowatt_amount', 'November', november_kilowatt_amount, setNovKw],
                    ['december_kilowatt_amount', 'December', december_kilowatt_amount, setDecKw],
                  ].map(([id, label, value, setValue]) => (
                    <div key={id} className="col-span-2 md:col-span-1">
                      <label htmlFor={id} className="block text-sm font-medium text-gray-900">
                        {label}
                      </label>
                      <input
                        type="text"
                        name={id}
                        id={id}
                        placeholder="kW"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                        value={value}
                        onChange={(evt) => setValue(evt.target.value)}
                      />
                    </div>
                  ))}

                  <div className="col-span-2 md:col-span-1">
                    <label htmlFor="annual_kilowatt_amount" className="block text-sm font-medium text-gray-900">
                      Total Annual Kilowatts
                    </label>
                    <input
                      type="text"
                      name="annual_kilowatt_amount"
                      id="annual_kilowatt_amount"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                      value={annual_kilowatt_amount}
                      readOnly
                    />
                  </div>
                </>
              )}

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="average_monthly_power_bill_cost" className="block text-sm font-medium text-gray-900">
                  Average Monthly Bill
                </label>
                <div className="mt-2 relative">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 w-full">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      $
                    </span>
                    <input
                      type="text"
                      name="average_monthly_power_bill_cost"
                      id="average_monthly_power_bill_cost"
                      className="flex-1 min-w-0 block w-full px-3 py-1.5 bg-white border-gray-300 rounded-none rounded-r-md focus:ring-indigo-600 focus:border-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="0.00"
                      value={average_monthly_power_bill_cost}
                      onChange={(evt) => {
                        const validValue = evt.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2})./g, '$1');
                        setMonthlyPowerBill(validValue);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <label htmlFor="additional_utility_notes" className="block text-sm font-medium text-gray-900">
                  Additional Utility Notes
                </label>
                <textarea
                  id="additional_utility_notes"
                  name="additional_utility_notes"
                  rows={3}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={additional_utility_notes}
                  onChange={(evt) => setUtilityNotes(evt.target.value)}
                ></textarea>
              </div>

              <div className="col-span-2 md:col-span-2 mt-6 flex justify-center md:justify-end gap-x-6">
                <button
                  type="submit"
                  className="w-full md:w-auto inline-flex justify-center rounded-md bg-blue-500 px-6 py-2 text-md font-semibold text-white shadow-md hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Update Utility Information
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
