import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, InboxIcon } from '@heroicons/react/24/outline';

export default function NotificationsPanel() {
  const [isNotificationPanelOpen, setNotificationPanelOpen] = useState(true);

  const notifications = [
    {
      id: 1,
      title: 'Discussion moved',
      description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
    },
    {
      id: 2,
      title: 'New message',
      description: 'You have received a new message from Jane Doe.',
    },
    {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      {
        id: 1,
        title: 'Discussion moved',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit voluptatum tenetur.',
      },
      {
        id: 2,
        title: 'New message',
        description: 'You have received a new message from Jane Doe.',
      },
      
    // Add more notifications as needed
  ];

  return (
    <Transition.Root show={isNotificationPanelOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40" onClose={setNotificationPanelOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="w-screen max-w-md">
                <div className="flex h-full flex-col overflow-hidden bg-white shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between py-4 border-b border-gray-200">
                      <Dialog.Title className="text-lg font-medium text-gray-900">Notifications</Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => setNotificationPanelOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 px-4 sm:px-6 overflow-y-auto">
                    {/* Notifications list */}
                    {notifications.map((notification) => (
                      <div key={notification.id} className="p-4 flex items-center space-x-4">
                        <InboxIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">{notification.title}</p>
                          <p className="text-sm text-gray-500">{notification.description}</p>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            type="button"
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            View
                          </button>
                          <button
                            type="button"
                            className="text-sm font-medium text-gray-600 hover:text-gray-500"
                          >
                            Dismiss
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
