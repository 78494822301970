import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import SideBarHeader from '../layout/AppHeader';
import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BillingInfo from '../components/website/BillingInfo.component';
import authenticationService from '../services/authorization-services/auth.service'; // Adjust the import path as needed
import useLoadingSpinner, { LoadingSpinnerOverlay } from '../components/LoadingSpinnerOverlay';
import toast from 'react-hot-toast';

const stripePromise = loadStripe('pk_test_51OtBeMEfGF19SeKdD24HguOohlbD9kzOB5KOlx4BGrABXvOEWENMWGyMHjl05GADCsHvpsK7sOmrKYAvb5aST7Yn00d5bUWCyl');

export default function Profile() {
  const [userData, setUser] = useState({});
  const [memberSince, setMemberSince] = useState('');
  const { isLoading, withMinimumSpinTime } = useLoadingSpinner();

  const [user_name, setUserName] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [account_type, setAccountType] = useState('');
  const [member_level, setMemberLevel] = useState('Sunin Minor');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [address_1, setAddress1] = useState('');
  const [address_2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    authenticationService.getUserProfile()
      .then(response => {
        setUser(response);
        const date = new Date(response.created_at).toLocaleDateString();
        setMemberSince(date || '');
        setUserName(response.user_name || '');
        setFirstName(response.first_name || '');
        setLastName(response.last_name || '');
        setAccountType(response.account_type || '');
        setMemberLevel(response.company_role || '');
        setEmail(response.email || '');
        setPhoneNumber(response.phone_number || '');
        setAddress1(response.address_1 || '');
        setAddress2(response.address_2 || '');
        setCity(response.city || '');
        setState(response.state || '');
        setZip(response.zip || '');
      })
      .catch(error => {
        console.error('Failed to fetch user info:', error);
      });
  }, []);

  /**
   * Submit updated user profile information to the server.
   *
   * @param {Object} data - User profile information data.
   */
  const submitUpdatedUserProfile = async (data) => {
    try {
      await authenticationService.updateUserProfile(data);
      toast.success('Updated contact information successfully!');
    } catch (error) {
      console.error('Error updating user profile: ', error);
      toast.error('Error updating contact information!');
    }
  };

  /**
   * Handle form submission and trigger update of user profile information.
   *
   * @param {React.FormEvent} e - Form event.
   */
  const handleUpdatedUser = (e) => {
    e.preventDefault();
    const data = {
      user_name,
      first_name,
      last_name,
      account_type,
      member_level,
      email,
      phone_number,
      address_1,
      address_2,
      city,
      state,
      zip
    };
    withMinimumSpinTime(() => submitUpdatedUserProfile(data));
  };

  const profileDetails = (
    <>
      {isLoading && <LoadingSpinnerOverlay />}

      <div className="mx-auto max-w-7xl sm:px-12 lg:px-12 mt-14 mb-14">
        <div className="space-y-10 sm:divide-y sm:divide-gray-900/10">

          {/* User Details */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-lg font-semibold text-gray-900">Profile</h2>
              <p className="mt-1 text-sm text-gray-600">
                This section displays your profile details that are visible to other members and SUNIN employees.
                If this information is not accurate, please reach out to <a href="mailto:techsupport@suninenergy.com" className="text-blue-600 hover:underline">techsupport@suninenergy.com</a>.
              </p>
            </div>
            <div className="md:col-span-2 bg-white sm:rounded-lg sm:border sm:shadow-lg">
              <div className="px-8 py-5 sm:p-6 sm:divide-y sm:divide-gray-200">
                <div className="grid max-w-xl grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6">
                  <div className="sm:col-span-2">
                    <div className="space-y-4">
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Full Name:</p>
                        <span className="text-sm text-gray-600">{`${first_name} ${last_name}`}</span>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Member Type:</p>
                        <span className="text-sm text-gray-600">
                          {account_type === 'sales_partner' ? 'Sales Partner' : account_type === 'referral_partner' ? 'Referral Partner' : ''}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Member Since:</p>
                        <span className="text-sm text-gray-600">{memberSince}</span>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Member Level:</p>
                        <span className="text-sm text-gray-600">
                          {account_type === 'sunin_minor' ? 'SUNIN Minor' : account_type === 'sunin_pro' ? 'SUNIN Pro' : account_type === 'sunin_ambassader' ? 'Sunin Ambassader' : 'Undefined'}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Username:</p>
                        <span className="text-sm text-gray-600">{user_name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Contact Details */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Contact Information</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
            </div>
            <form className="bg-white sm:rounded-xl md:col-span-2 sm:border sm:shadow-lg" onSubmit={handleUpdatedUser}>
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                    <div className="mt-2">
                      <input
                        id="phone_number"
                        name="phone_number"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={phone_number}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="address_1" className="block text-sm font-medium leading-6 text-gray-900">Street address line 1</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="address_1"
                        id="address_1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={address_1}
                        onChange={(e) => setAddress1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label htmlFor="address_2" className="block text-sm font-medium leading-6 text-gray-900">Street address line 2</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="address_2"
                        id="address_2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={address_2}
                        onChange={(e) => setAddress2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:col-start-1">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">City</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">State / Province</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="state"
                        id="state"
                        autoComplete="address-level1"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="zip" className="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code</label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="zip"
                        id="zip"
                        autoComplete="postal-code"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 px-4 py-4 sm:px-8">
                <button
                  type="submit"
                  className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  Update Contact Information
                </button>
              </div>
            </form>
          </div>

          {/* Billing Details */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
            <div className="px-4 sm:px-0">
              <h2 className="text-base font-semibold leading-7 text-gray-900">Billing Information</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">Manage your subscriptions securely with our powerful Stripe integration!</p>
            </div>
            <Elements stripe={stripePromise}>
              <BillingInfo user={userData} />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );

  return profileDetails;
}
