import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import projectService from '../../services/project-services/projects.service';
import { LoadingSpinnerOverlay } from '../LoadingSpinnerOverlay';

export default function ProjectAssets({ project, fetchProject }) {
    const [photos, setPhotos] = useState([]);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
    const [isFileModalOpen, setIsFileModalOpen] = useState(false);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const modalContentRef = useRef(null);
    const thumbnailContainerRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchPhotos();
        fetchFiles();
    }, []);

    useEffect(() => {
        if (isPhotoModalOpen || isFileModalOpen) {
            centerThumbnail(currentPhotoIndex);
        }
    }, [currentPhotoIndex, currentFileIndex, isPhotoModalOpen, isFileModalOpen]);

    const centerThumbnail = (index) => {
        if (thumbnailContainerRef.current) {
            const thumbnails = thumbnailContainerRef.current.children;
            if (thumbnails[index]) {
                scrollToCenter(thumbnails[index]);
            }
        }
    };

    const scrollToCenter = (element) => {
        if (element && element.scrollIntoView) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };

    const fetchPhotos = async () => {
        try {
            const response = await projectService.getPhotos();
            setPhotos(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch photos:", error);
            toast.error('Failed to fetch photos.');
            setIsLoading(false);
        }
    };

    const fetchFiles = async () => {
        try {
            const response = await projectService.getFiles();
            setFiles(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch files:", error);
            toast.error('Failed to fetch files.');
            setIsLoading(false);
        }
    };


    const handleAddPhotos = async (event) => {
        setIsLoading(true);
        const files = event.target.files;
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append("images[]", file);
        });

        try {
            await projectService.newPhoto(formData);
            toast.success('Photos added successfully!');
            fetchPhotos();
        } catch (error) {
            console.error("Failed to add photos:", error);
            toast.error('Failed to add photos.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddFiles = async (event) => {
        setIsLoading(true);
        const files = event.target.files;
        const formData = new FormData();
        Array.from(files).forEach(file => {
            if (file.type !== 'application/pdf') {
                toast.error('Only PDF files are allowed.');
                setIsLoading(false);
                return;
            }
            formData.append("files[]", file);
        });

        try {
            await projectService.newFiles(formData);
            toast.success('Files added successfully!');
            fetchFiles();
        } catch (error) {
            console.error("Failed to add files:", error);
            toast.error('Failed to add files.');
        } finally {
            setIsLoading(false);
        }
    };

    const deletePhoto = async (photoId) => {
        setIsLoading(true);
        try {
            await projectService.deletePhoto(photoId);
            toast.success('Photo deleted successfully!');
            setPhotos(photos.filter(photo => photo.id !== photoId));
        } catch (error) {
            console.error("Failed to delete photo:", error);
            toast.error('Failed to delete photo.');
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFile = async (fileId) => {
        setIsLoading(true);
        try {
            await projectService.deleteFile(fileId);
            toast.success('File deleted successfully!');
            setFiles(files.filter(file => file.id !== fileId));
        } catch (error) {
            console.error("Failed to delete file:", error);
            toast.error('Failed to delete file.');
        } finally {
            setIsLoading(false);
        }
    };

    const downloadPhoto = (photoUrl, fileName) => {
        const link = document.createElement('a');
        link.href = photoUrl;
        link.download = fileName;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadFile = (fileUrl, fileName) => {
        const secureUrl = fileUrl.replace("http://", "https://"); // Ensure the URL is secure
        const link = document.createElement('a');
        link.href = secureUrl;
        link.download = fileName;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const openPhotoModal = (index) => {
        setCurrentPhotoIndex(index);
        setIsPhotoModalOpen(true);
    };

    const openFileModal = (index) => {
        setCurrentFileIndex(index);
        setIsFileModalOpen(true);
    };

    const closePhotoModal = () => {
        setIsPhotoModalOpen(false);
    };

    const closeFileModal = () => {
        setIsFileModalOpen(false);
    };

    const prevPhoto = () => {
        const newIndex = (currentPhotoIndex - 1 + photos.length) % photos.length;
        setCurrentPhotoIndex(newIndex);
        centerThumbnail(newIndex); // Ensure this is called to center the thumbnail
    };
    
    const nextPhoto = () => {
        const newIndex = (currentPhotoIndex + 1) % photos.length;
        setCurrentPhotoIndex(newIndex);
        centerThumbnail(newIndex); // Ensure this is called to center the thumbnail
    };
    
    const prevFile = () => {
        const newIndex = (currentFileIndex - 1 + files.length) % files.length;
        setCurrentFileIndex(newIndex);
        centerThumbnail(newIndex); // Ensure this is called to center the thumbnail
    };
    
    const nextFile = () => {
        const newIndex = (currentFileIndex + 1) % files.length;
        setCurrentFileIndex(newIndex);
        centerThumbnail(newIndex); // Ensure this is called to center the thumbnail
    };

    const scrollThumbnails = (direction) => {
        if (thumbnailContainerRef.current) {
            const container = thumbnailContainerRef.current;
            const scrollAmount = 250; // Adjust scroll amount as needed
            if (direction === 'left') {
                container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
            } else if (direction === 'right') {
                container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
            }
        }
    };


    return (
        <>
            {isLoading && <LoadingSpinnerOverlay />} 
            <div className="flex justify-center mt-5">
                <div className="w-full max-w-7xl mb-12">
                    <div className="rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100" ref={modalContentRef}>
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-semibold text-gray-700 py-2">Project Photos</h2>
                       
                                <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none">
                                    Add Photos
                                    <input type="file" accept="image/*" multiple onChange={handleAddPhotos} className="hidden" />
                                </label>
                           
                        </div>
                        <div className="mt-5 h-70 overflow-y-auto">
                            {photos.length > 0 && (
                                <div className="space-y-4 overflow-y-auto max-h-64">
                                    {photos.map((photo, index) => (
                                        <div key={index} className="flex items-center bg-white shadow-md rounded-lg overflow-hidden border border-gray-300 hover:shadow-lg transition-shadow duration-200 ease-in-out p-1 cursor-pointer" onClick={() => openPhotoModal(index)}>
                                            <div className="flex-shrink-0 mr-2">
                                                <img className="w-12 h-12 object-cover rounded" src={photo.url} alt={`${index + 1}`} />
                                            </div>
                                            <div className="hidden md:block flex-grow max-w-xs">
                                                <h5 className="text-sm font-semi-bold text-gray-800 truncate">{photo.name}</h5>
                                            </div>
                                            <div className="flex space-x-4 ml-auto">
                                                <button onClick={(e) => { e.stopPropagation(); downloadPhoto(photo.url, `Photo-${index}.jpg`); }} className="btn-download flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-150 ease-in-out">
                                                    <svg className="w-6 h-6 md:h-4 w-4  mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-0.9 2-2V4H4z"></path>
                                                        <polyline points="16 2 16 6 8 6 8 2"></polyline>
                                                        <line x1="12" y1="11" x2="12" y2="17"></line>
                                                        <polyline points="9 14 12 17 15 14"></polyline>
                                                    </svg>
                                                    <span className="hidden md:inline">Download</span>
                                                </button>
                                                <button onClick={(e) => { e.stopPropagation(); deletePhoto(photo.id); }} className="btn-delete flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out">
                                                    <svg className="w-6 h-6 md:h-4 w-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M3 6h18"></path>
                                                        <path d="M19 6v14c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6m3 0V4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v2"></path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                    <span className="hidden md:inline mx-5">Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-5 rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100" ref={modalContentRef}>
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-semibold text-gray-700 py-2">Project Files</h2>
                                <label className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none">
                                    Add Files
                                    <input type="file" accept="application/pdf" multiple onChange={handleAddFiles} className="hidden" />
                                </label>
                            
                        </div>
                        <div className="mt-5 h-70 overflow-y-auto">
                            {files.length > 0 && (
                                <div className="space-y-4 overflow-y-auto max-h-64">
                                    {files.map((file, index) => (
                                        <div key={index} className="flex items-center bg-white shadow-md rounded-lg overflow-hidden border border-gray-300 hover:shadow-lg transition-shadow duration-200 ease-in-out p-1 cursor-pointer" onClick={() => openFileModal(index)}>
                                            <div className="flex-shrink-0 mr-2">
                                                <div className="w-12 h-12 bg-gray-300 flex items-center justify-center rounded">
                                                    <svg className="w-6 h-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h10M7 11h10M7 15h10" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="hidden md:block flex-grow max-w-xs">
                                                <h5 className="text-sm font-semi-bold text-gray-800 truncate">{file.name}</h5>
                                            </div>
                                            <div className="flex space-x-4 ml-auto">
                                                <button onClick={(e) => { e.stopPropagation(); downloadFile(file.url, `File-${index}.pdf`); }} className="btn-download flex items-center text-blue-600 hover:text-blue-700 transition-colors duration-150 ease-in-out">
                                                    <svg className="w-6 h-6 md:h-4 w-4  mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-0.9 2-2V4H4z"></path>
                                                        <polyline points="16 2 16 6 8 6 8 2"></polyline>
                                                        <line x1="12" y1="11" x2="12" y2="17"></line>
                                                        <polyline points="9 14 12 17 15 14"></polyline>
                                                    </svg>
                                                    <span className="hidden md:inline">Download</span>
                                                </button>
                                                <button onClick={(e) => { e.stopPropagation(); deleteFile(file.id); }} className="btn-delete flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out">
                                                    <svg className="w-6 h-6 md:h-4 w-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path d="M3 6h18"></path>
                                                        <path d="M19 6v14c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V6m3 0V4c0-1.1.9-2 2-2h4c1.1 0 2 .9 2 2v2"></path>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                    </svg>
                                                    <span className="hidden md:inline mx-5">Delete</span>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {isPhotoModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-85 flex items-center justify-center z-50">
                    <div className="relative bg-black p-6 rounded-lg w-11/12 h-5/6 max-w-4xl flex flex-col">
                        <button onClick={closePhotoModal} className="absolute top-4 right-4 text-white hover:text-gray-300">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="flex justify-between items-center flex-grow">
                            <button onClick={prevPhoto} className="text-white hover:text-gray-300">
                                <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <div className="flex items-center justify-center w-full h-full">
                                <img src={photos[currentPhotoIndex].url} alt="Current" style={{ width: '40rem', height: '40rem', objectFit: 'contain' }} />
                            </div>
                            <button onClick={nextPhoto} className="text-white hover:text-gray-300">
                                <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                        <div className="relative mt-4 flex items-center justify-center h-16">
                            <button
                                className="absolute left-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                                onClick={() => scrollThumbnails('left')}
                            >
                                <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <div className="flex-1 mx-12 overflow-x-auto" ref={thumbnailContainerRef}>
                                <div className="flex space-x-2 items-center">
                                    {photos.map((photo, index) => (
                                        <img
                                            key={index}
                                            src={photo.url}
                                            alt={`Thumbnail ${index}`}
                                            className={`w-12 h-12 object-cover rounded cursor-pointer ${currentPhotoIndex === index ? 'w-16 h-16 ring-2 ring-blue-500' : ''}`}
                                            onClick={() => {
                                                setCurrentPhotoIndex(index);
                                                centerThumbnail(index);
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <button
                                className="absolute right-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                                onClick={() => scrollThumbnails('right')}
                            >
                                <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isFileModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-85 flex items-center justify-center z-50">
                    <div className="relative bg-black p-6 rounded-lg w-11/12 h-5/6 max-w-4xl flex flex-col">
                        <button onClick={closeFileModal} className="absolute top-4 right-4 text-white hover:text-gray-300">
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div className="flex justify-between items-center flex-grow">
                            <button onClick={prevFile} className="text-white hover:text-gray-300">
                                <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <div className="flex items-center justify-center w-full h-full">
                                <embed src={files[currentFileIndex].url} type="application/pdf" style={{ width: '40rem', height: '40rem', objectFit: 'contain' }} />
                            </div>
                            <button onClick={nextFile} className="text-white hover:text-gray-300">
                                <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                        <div className="relative mt-4 flex items-center justify-center h-16">
                            <button
                                className="absolute left-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                                onClick={() => scrollThumbnails('left')}
                            >
                                <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                            </button>
                            <div className="flex-1 mx-12 overflow-x-auto" ref={thumbnailContainerRef}>
                                <div className="flex space-x-2 items-center">
                                    {files.map((file, index) => (
                                        <div
                                            key={index}
                                            className={`w-12 h-12 bg-gray-700 rounded cursor-pointer flex items-center justify-center ${currentFileIndex === index ? 'ring-2 ring-blue-500' : ''}`}
                                            onClick={() => setCurrentFileIndex(index)}
                                        >
                                            <svg className="w-8 h-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h10M7 11h10M7 15h10" />
                                            </svg>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button
                                className="absolute right-1 bg-gray-200 p-1 rounded-full hover:bg-gray-400 z-20"
                                onClick={() => scrollThumbnails('right')}
                            >
                                <svg className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
