import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import projectService from '../../services/project-services/projects.service';
import useLoadingSpinner, { LoadingSpinnerOverlay } from '../LoadingSpinnerOverlay';

/**
 * Combined component to display and update company and property information.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.project - Project object containing company and property information.
 * @param {Function} props.fetchProject - Function to fetch the latest project data.
 * @returns {JSX.Element}
 */
export default function CompanyPropertyInformation({ project, fetchProject }) {
  const [accountId, setAccountId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [address1, setAddressOne] = useState('');
  const [address2, setAddressTwo] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [shadeInterference, setShadeInterference] = useState('');
  const [shadeInterferenceDetails, setShadeDescription] = useState('');
  const [typeOfRoof, setTypeOfRoof] = useState('');
  const [ageOfRoof, setAgeOfRoof] = useState('');
  const [roofDescription, setRoofDescription] = useState('');
  const { isLoading, withMinimumSpinTime } = useLoadingSpinner();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (project) {
      setCompanyName(project.company_name || '');
      setAccountId(project.account_id || '');
      setAddressOne(project.address_1 || '');
      setAddressTwo(project.address_2 || '');
      setCity(project.city || '');
      setState(project.state || '');
      setZip(project.zip || '');
      setShadeInterference(project.shade_interference || ''); 
      setShadeDescription(project.shade_interference_details || '');
      setTypeOfRoof(project.type_of_roof || '');
      setAgeOfRoof(project.age_of_roof || '');
      setRoofDescription(project.roof_description || '');
    }
  }, [project]);

  const submitUpdatedCompanyPropertyInfo = async (data) => {
    try {
      await projectService.updateProject(data);
      fetchProject();
      toast.success('Updated site information successfully!');
    } catch (error) {
      console.error('Error updating information: ', error);
      toast.error('Error updating information!');
    }
  };

  const handleUpdatedCompanyPropertyInfo = (e) => {
    e.preventDefault();
    const data = {
      company_name: companyName,
      account_id: accountId,
      address_1: address1,
      address_2: address2,
      city,
      state,
      zip,
      shade_interference: shadeInterference,
      shade_interference_details: shadeInterferenceDetails,
      type_of_roof: typeOfRoof,
      age_of_roof: ageOfRoof,
      roof_description: roofDescription,
    };
    withMinimumSpinTime(() => submitUpdatedCompanyPropertyInfo(data));
  };

  return (
    <>
      {isLoading && <LoadingSpinnerOverlay />}

      <div className="flex justify-center mt-5">
        <div className="w-full max-w-7xl">
          <div className="rounded-lg bg-white px-4 py-4 shadow-lg border border-gray-100">
            <h2 className="text-2xl font-semibold text-gray-700 py-2">Site Information</h2>
            <form onSubmit={handleUpdatedCompanyPropertyInfo} className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5">
              
              {/* Company Information */}
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="company_name" className="block text-sm font-medium text-gray-900">
                  Company
                </label>
                <input
                  type="text"
                  name="company_name"
                  id="company_name"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={companyName}
                  onChange={(evt) => setCompanyName(evt.target.value)}
                />
              </div>

              <div className="hidden sm:block">
                {/* Placeholder Div */}
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                  Street Address line 1
                </label>
                <input
                  type="text"
                  name="address_1"
                  id="address_1"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={address1}
                  onChange={(evt) => setAddressOne(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="address_2" className="block text-sm font-medium text-gray-900">
                  Address line 2
                </label>
                <input
                  type="text"
                  name="address_2"
                  id="address_2"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={address2}
                  onChange={(evt) => setAddressTwo(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="city" className="block text-sm font-medium text-gray-900">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  autoComplete="address-level1"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={city}
                  onChange={(evt) => setCity(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="state" className="block text-sm font-medium text-gray-900">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  autoComplete="address-level1"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={state}
                  onChange={(evt) => setState(evt.target.value)}
                />
              </div>

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="zip" className="block text-sm font-medium text-gray-900">
                  ZIP
                </label>
                <input
                  type="text"
                  name="zip"
                  id="zip"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={zip}
                  onChange={(evt) => setZip(evt.target.value)}
                />
              </div>

              
              <div className="hidden sm:block">
                {/* Placeholder Div */}
              </div>

              {/* Property Information */}
              <div className="col-span-2 md:col-span-1">
                <label htmlFor="shade_interference" className="block text-sm font-medium text-gray-900">
                  Shade Interference
                </label>
                <select
                  id="shade_interference"
                  name="shade_interference"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={shadeInterference}
                  onChange={(evt) => setShadeInterference(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              {shadeInterference !== 'Yes' && (
                 <div className="hidden sm:block">
                 {/* Placeholder Div */}
               </div>
              )}

              {shadeInterference === 'Yes' && (
                <div className="col-span-2">
                  <label htmlFor="shade_interference_details" className="block text-sm font-medium text-gray-900">
                    Shade Description
                  </label>
                  <textarea
                    id="shade_interference_details"
                    name="shade_interference_details"
                    rows={3}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                    value={shadeInterferenceDetails}
                    onChange={(evt) => setShadeDescription(evt.target.value)}
                  ></textarea>
                </div>
              )}

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="type_of_roof" className="block text-sm font-medium text-gray-900">
                  Type of Roof
                </label>
                <select
                  id="type_of_roof"
                  name="type_of_roof"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={typeOfRoof}
                  onChange={(evt) => setTypeOfRoof(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="Concrete Tile">Concrete Tile</option>
                  <option value="Asphalt Shingle">Asphalt Shingle</option>
                  <option value="Metal Roofing">Metal Roofing</option>
                  <option value="Flat Roof">Flat Roof</option>
                  <option value="Rubber (EPDM)">Rubber (EPDM)</option>
                  <option value="TPO and PVC Roofing">TPO and PVC Roofing</option>
                  <option value="Slate Tile">Slate Tile</option>
                  <option value="Clay Tile">Clay Tile</option>
                  <option value="Wood Shingles/Shakes">Wood Shingles/Shakes</option>
                  <option value="Green Roof">Green Roof</option>
                  <option value="Built-Up Roofing (BUR)">Built-Up Roofing (BUR)</option>
                  <option value="Composite Roofing">Composite Roofing</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {shadeInterference === 'no' && (
                <div className="hidden sm:block md:col-span-2">
                {/* Placeholder Div */}
              </div>
              )}

              <div className="col-span-2 md:col-span-1">
                <label htmlFor="age_of_roof" className="block text-sm font-medium text-gray-900">
                  Age of Roof
                </label>
                <select
                  id="age_of_roof"
                  name="age_of_roof"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={ageOfRoof}
                  onChange={(evt) => setAgeOfRoof(evt.target.value)}
                >
                  <option value="">Select...</option>
                  <option value="0-5 Years">0-5 Years</option>
                  <option value="6-10 Years">6-10 Years</option>
                  <option value="11-15 Years">11-15 Years</option>
                  <option value="16-20 Years">16-20 Years</option>
                  <option value="21+ Years">21+ Years</option>
                </select>
              </div>

              <div className="col-span-2">
                <label htmlFor="roof_description" className="block text-sm font-medium text-gray-900">
                  Roof Description
                </label>
                <textarea
                  id="roof_description"
                  name="roof_description"
                  rows={3}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  value={roofDescription}
                  onChange={(evt) => setRoofDescription(evt.target.value)}
                ></textarea>
              </div>
              <div className="col-span-2 md:col-span-2 mt-6 flex justify-center md:justify-end gap-x-6">
                <button
                  type="submit"
                  className="w-full md:w-auto inline-flex justify-center rounded-md bg-blue-500 px-6 py-2 text-md font-semibold text-white shadow-md hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                >
                  Update Site Information
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
