import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import 'react-toastify/dist/ReactToastify.css';
import projectService from '../../services/project-services/projects.service';

/**
 * NewLinkModal Component
 * 
 * This component renders a modal to add new links to a project.
 * 
 * @param {Object} props - Component properties
 * @param {Function} props.setIsNewLinkModalOpen - Function to toggle the modal's open state
 * @param {boolean} props.isNewLinkModalOpen - Boolean indicating if the modal is open
 * @param {Function} props.fetchLinks - Function to refetch the links after a new one is added
 * @param {Object} props.toast - Toast notification instance
 */
export default function NewLinkModal({ setIsNewLinkModalOpen, isNewLinkModalOpen, fetchLinks, toast }) {
  const cancelButtonRef = useRef(null);
  const [linkType, setLinkType] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [linkURL, setLinkURL] = useState('');

  /**
   * Resets all input fields to their initial empty state.
   */
  const resetFields = () => {
    setLinkType('');
    setLinkTitle('');
    setLinkURL('');
  };


  /**
   * Submits new link information to the server.
   */
  const submitNewLinkInfo = async () => {
    const data = {
      link_type: linkType,
      link_title: linkTitle,
      link_url: linkURL,
    };
    try {
      await projectService.newLink(data);
      fetchLinks();
      setIsNewLinkModalOpen(false);
      resetFields();
      toast.success('Link added successfully!');
    } catch (error) {
      console.error('Error adding new link:', error);
      toast.error('Failed to add new link.');
    }
  };


  return (
    <Transition.Root show={isNewLinkModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" initialFocus={cancelButtonRef} onClose={setIsNewLinkModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg md:max-w-xl sm:p-6">
                <div>
                  {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div> */}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      New Link
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Feel free to add as many relevant links as you want to each project.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <label htmlFor="link_type" className="block text-sm font-medium leading-6 text-gray-900">
                    Link type
                  </label>
                  <div className="mt-1 mb-2">
                    <select
                      id="link_type"
                      name="link_type"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={linkType}
                      onChange={(evt) => setLinkType(evt.target.value)}
                    >
                      <option>Select...</option>
                      <option>Finance</option>
                      <option>Proposal</option>
                      <option>Utility</option>
                      <option>Calendar</option>
                      <option>Website</option>
                      <option>Other</option>
                    </select>
                  </div>

                  <label htmlFor="link_title" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                    Link title
                  </label>
                  <div className="mt-1 mb-2">
                    <input
                      type="text"
                      name="link_title"
                      id="link_title"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={linkTitle}
                      onChange={(evt) => setLinkTitle(evt.target.value)}
                    />
                  </div>

                  <label htmlFor="link_url" className="block text-sm font-medium leading-6 text-gray-900 mt-4">
                    Link URL
                  </label>
                  <div className="mt-1 mb-2">
                    <input
                      type="text"
                      name="link_url"
                      id="link_url"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={linkURL}
                      onChange={(evt) => setLinkURL(evt.target.value)}
                    />
                  </div>
                </div>
                
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                    onClick={submitNewLinkInfo}
                  >
                    Add Link
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsNewLinkModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
