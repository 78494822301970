import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import authenticationService from "../../services/authorization-services/auth.service";
import accountService from "../../services/account-services/accounts.service";
import useLoadingSpinner, { LoadingSpinnerOverlay } from "../LoadingSpinnerOverlay";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    authenticationService.getUserProfile().then((res) => {
      if (res.subscription_status === 'active' || res.subscription_status === 'pending_cancelation') {
        navigate('/home');
      } else {
        accountService.createCheckoutSession()
          .then((response) => {
            setClientSecret(response.data.clientSecret);
          });
      }
    });
  }, [navigate]); 

  return (
    <div id="checkout">
      {clientSecret ? (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      ) : 
      <LoadingSpinnerOverlay/>
      }
    </div>
  )
}

export default CheckoutForm;