import http from "../../http-common";
import serviceUtil from "../../utils/service-util";

class AdminService {
  // ------------- USERS SECTION ------------- //




  /**
   * Retrieves a list of all users.
   * @returns {Promise} The server response with the list of users.
   */
  async getUsers() {
    return await http.get("/admin/users");
  }

  /**
   * Retrieves a single user by its ID.
   * @param {string} id - The ID of the user.
   * @returns {Promise} The server response with the user data.
   */
  async getUser(id) {
    return await http.get(`/admin/users/${id}`);
  }

  /**
   * Creates a new user.
   * @param {Object} data - User data.
   * @returns {Promise} The server response.
   */
  async createUser(data) {
    return await http.post("/admin/users", { user: data });
  }

  /**
   * Updates an existing user.
   * @param {string} id - The ID of the user.
   * @param {Object} data - Updated user data.
   * @returns {Promise} The server response.
   */
  async updateUser(id, data) {
    return await http.put(`/admin/users/${id}`, { user: data });
  }

  /**
   * Deletes a user.
   * @param {string} id - The ID of the user.
   * @returns {Promise} The server response.
   */
  async deleteUser(id) {
    return await http.delete(`/admin/users/${id}`);
  }

  // ------------- REFERRALS SECTION ------------- //

  /**
   * Retrieves all leads.
   * @returns {Promise} The server response with the list of leads.
   */
  async getAllLeads() {
    return await http.get("/admin/leads");
  }

  /**
   * Retrieves leads associated with a specific user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise} The server response with the list of leads.
   */
  async getLeads(userId) {
    return await http.get(`/admin/users/${userId}/leads`);
  }

  /**
   * Retrieves a single lead by its ID.
   * @param {string} userId - The ID of the user.
   * @param {string} leadId - The ID of the lead.
   * @returns {Promise} The server response with the lead data.
   */
  async getLead(userId, leadId) {
    return await http.get(`/admin/users/${userId}/leads/${leadId}`);
  }

  /**
   * Creates a new lead for a specific user.
   * @param {string} userId - The ID of the user.
   * @param {Object} data - Lead data.
   * @returns {Promise} The server response.
   */
  async createLead(userId, data) {
    return await http.post(`/admin/users/${userId}/leads`, { lead: data });
  }

  /**
   * Updates an existing lead.
   * @param {string} userId - The ID of the user.
   * @param {string} leadId - The ID of the lead.
   * @param {Object} data - Updated lead data.
   * @returns {Promise} The server response.
   */
  async updateLead(userId, leadId, data) {
    return await http.put(`/admin/users/${userId}/leads/${leadId}`, { lead: data });
  }

  /**
   * Deletes a lead.
   * @param {string} userId - The ID of the user.
   * @param {string} leadId - The ID of the lead.
   * @returns {Promise} The server response.
   */
  async deleteLead(userId, leadId) {
    return await http.delete(`/admin/users/${userId}/leads/${leadId}`);
  }

  // ------------- ACCOUNTS SECTION ------------- //

  /**
   * Retrieves all accounts.
   * @returns {Promise} The server response with the list of accounts.
   */
  async getAllAccounts() {
    return await http.get("/admin/accounts");
  }

  /**
   * Retrieves accounts associated with a specific user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise} The server response with the list of accounts.
   */
  async getAccounts(userId) {
    return await http.get(`/admin/users/${userId}/accounts`);
  }

  /**
   * Retrieves a single account by its ID.
   * @param {string} accountId - The ID of the account.
   * @returns {Promise} The server response with the account data.
   */
  async getAccount(accountId) {
    if (!accountId) {
      const url = window.location.href;
      const parts = url.split('/');
      const accountId = parts[parts.length - 1];
      return await http.get(`/admin/accounts/${accountId}`);
    } else {
      return await http.get(`/admin/accounts/${accountId}`);
    }
  }

  /**
   * Creates a new account.
   * @param {Object} data - Account data.
   * @returns {Promise} The server response.
   */
  async createAccount(data) {
    return await http.post("/admin/accounts", { account: data });
  }

  /**
   * Updates an existing account.
   * @param {string} accountId - The ID of the account.
   * @param {Object} data - Updated account data.
   * @returns {Promise} The server response.
   */
  async updateAccount(accountId, data) {
    return await http.put(`/admin/accounts/${accountId}`, { account: data });
  }

  /**
   * Deletes an account.
   * @param {string} accountId - The ID of the account.
   * @returns {Promise} The server response.
   */
  async deleteAccount(accountId) {
    return await http.delete(`/admin/accounts/${accountId}`);
  }

  // ------------- PROJECTS SECTION ------------- //

  /**
   * Retrieves all projects.
   * @returns {Promise} The server response with the list of projects.
   */
  async getAllProjects() {
    return await http.get('/admin/projects');
  }

  /**
   * Retrieves projects associated with a specific user.
   * @param {string} userId - The ID of the user.
   * @returns {Promise} The server response with the list of projects.
   */
  async getProjects(userId) {
    return await http.get(`/admin/users/${userId}/projects`);
  }

  /**
   * Retrieves a single project by its ID.
   * @returns {Promise} The server response with the project data.
   */
  async getProject() {
    const id = serviceUtil.extrapolateFinalIdFromUrl();
    return await http.get(`/admin/projects/${id}`);
  }

  /**
   * Creates a new project.
   * @param {Object} data - Project data.
   * @returns {Promise} The server response.
   */
  async createProject(data) {
    return await http.post('/admin/projects', { project: data });
  }

  /**
   * Updates an existing project.
   * @param {string} projectId - The ID of the project.
   * @param {Object} data - Updated project data.
   * @returns {Promise} The server response.
   */
  async updateProject(projectId, data) {
    return await http.put(`/admin/projects/${projectId}`, { project: data });
  }

  /**
   * Deletes a project.
   * @param {string} projectId - The ID of the project.
   * @returns {Promise} The server response.
   */
  async deleteProject(projectId) {
    return await http.delete(`/admin/projects/${projectId}`);
  }

  // ------------- PROJECT CONTACTS SECTION ------------- //

  /**
   * Retrieves all contacts for a specific project.
   * @param {string} projectId - The ID of the project.
   * @returns {Promise} The server response with the list of contacts.
   */
  async getProjectContacts(projectId) {
    return await http.get(`/admin/projects/${projectId}/contacts`);
  }

  /**
   * Retrieves a single contact for a project by its ID.
   * @param {string} projectId - The ID of the project.
   * @param {string} contactId - The ID of the contact.
   * @returns {Promise} The server response with the contact data.
   */
  async getProjectContact(projectId, contactId) {
    return await http.get(`/admin/projects/${projectId}/contacts/${contactId}`);
  }

  /**
   * Creates a new contact for a project.
   * @param {string} projectId - The ID of the project.
   * @param {Object} data - Contact data.
   * @returns {Promise} The server response.
   */
  async createProjectContact(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/contacts`, { contact: data });
  }

  /**
   * Updates an existing contact for a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} contactId - The ID of the contact.
   * @param {Object} data - Updated contact data.
   * @returns {Promise} The server response.
   */
  async updateProjectContact(projectId, contactId, data) {
    return await http.put(`/admin/projects/${projectId}/contacts/${contactId}`, { contact: data });
  }

  /**
   * Deletes a contact from a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} contactId - The ID of the contact.
   * @returns {Promise} The server response.
   */
  async deleteProjectContact(projectId, contactId) {
    return await http.delete(`/admin/projects/${projectId}/contacts/${contactId}`);
  }

  // ------------- PROJECT LINKS SECTION ------------- //

  /**
   * Retrieves all links for a specific project.
   * @param {string} projectId - The ID of the project.
   * @returns {Promise} The server response with the list of links.
   */
  async getProjectLinks(projectId) {
    return await http.get(`/admin/projects/${projectId}/project_links`);
  }

  /**
   * Retrieves a single link for a project by its ID.
   * @param {string} projectId - The ID of the project.
   * @param {string} linkId - The ID of the link.
   * @returns {Promise} The server response with the link data.
   */
  async getProjectLink(projectId, linkId) {
    return await http.get(`/admin/projects/${projectId}/project_links/${linkId}`);
  }

  /**
   * Creates a new link for a project.
   * @param {string} projectId - The ID of the project.
   * @param {Object} data - Link data.
   * @returns {Promise} The server response.
   */
  async createProjectLink(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/project_links`, { project_link: data });
  }

  /**
   * Updates an existing link for a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} linkId - The ID of the link.
   * @param {Object} data - Updated link data.
   * @returns {Promise} The server response.
   */
  async updateProjectLink(projectId, linkId, data) {
    return await http.put(`/admin/projects/${projectId}/project_links/${linkId}`, { project_link: data });
  }

  /**
   * Deletes a link from a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} linkId - The ID of the link.
   * @returns {Promise} The server response.
   */
  async deleteProjectLink(projectId, linkId) {
    return await http.delete(`/admin/projects/${projectId}/project_links/${linkId}`);
  }

  // ------------- PROJECT IMAGES SECTION ------------- //

  /**
   * Retrieves all images for a specific project.
   * @param {string} projectId - The ID of the project.
   * @returns {Promise} The server response with the list of images.
   */
  async getProjectImages(projectId) {
    return await http.get(`/admin/projects/${projectId}/images`);
  }

  /**
   * Retrieves a single image for a project by its ID.
   * @param {string} projectId - The ID of the project.
   * @param {string} imageId - The ID of the image.
   * @returns {Promise} The server response with the image data.
   */
  async getProjectImage(projectId, imageId) {
    return await http.get(`/admin/projects/${projectId}/images/${imageId}`);
  }

  /**
   * Creates a new image for a project.
   * @param {string} projectId - The ID of the project.
   * @param {Object} data - Image data.
   * @returns {Promise} The server response.
   */
  async createProjectImage(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/images`, { image: data });
  }

  /**
   * Updates an existing image for a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} imageId - The ID of the image.
   * @param {Object} data - Updated image data.
   * @returns {Promise} The server response.
   */
  async updateProjectImage(projectId, imageId, data) {
    return await http.put(`/admin/projects/${projectId}/images/${imageId}`, { image: data });
  }

  /**
   * Deletes an image from a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} imageId - The ID of the image.
   * @returns {Promise} The server response.
   */
  async deleteProjectImage(projectId, imageId) {
    return await http.delete(`/admin/projects/${projectId}/images/${imageId}`);
  }

  // ------------- PROJECT FILES SECTION ------------- //

  /**
   * Retrieves all files for a specific project.
   * @param {string} projectId - The ID of the project.
   * @returns {Promise} The server response with the list of files.
   */
  async getProjectFiles(projectId) {
    return await http.get(`/admin/projects/${projectId}/files`);
  }

  /**
   * Retrieves a single file for a project by its ID.
   * @param {string} projectId - The ID of the project.
   * @param {string} fileId - The ID of the file.
   * @returns {Promise} The server response with the file data.
   */
  async getProjectFile(projectId, fileId) {
    return await http.get(`/admin/projects/${projectId}/files/${fileId}`);
  }

  /**
   * Creates a new file for a project.
   * @param {string} projectId - The ID of the project.
   * @param {Object} data - File data.
   * @returns {Promise} The server response.
   */
  async createProjectFile(projectId, data) {
    return await http.post(`/admin/projects/${projectId}/files`, { file: data });
  }

  /**
   * Updates an existing file for a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} fileId - The ID of the file.
   * @param {Object} data - Updated file data.
   * @returns {Promise} The server response.
   */
  async updateProjectFile(projectId, fileId, data) {
    return await http.put(`/admin/projects/${projectId}/files/${fileId}`, { file: data });
  }

  /**
   * Deletes a file from a project.
   * @param {string} projectId - The ID of the project.
   * @param {string} fileId - The ID of the file.
   * @returns {Promise} The server response.
   */
  async deleteProjectFile(projectId, fileId) {
    return await http.delete(`/admin/projects/${projectId}/files/${fileId}`);
  }

/**
   * Retrieves all proposal tickets.
   * @returns {Promise} The server response with the list of proposal tickets.
   */
async getProposals() {
  return await http.get("/admin/proposal_tickets");
}

/**
 * Updates an existing proposal ticket.
 * @param {string} proposalId - The ID of the proposal ticket.
 * @param {Object} data - Updated proposal ticket data.
 * @returns {Promise} The server response.
 */
async updateProposal(proposalId, data) {
  return await http.put(`/admin/proposal_tickets/${proposalId}`, { proposal_ticket: data });
}


}

const adminService = new AdminService();
export default adminService;
