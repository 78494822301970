import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import projectService from "../../services/project-services/projects.service";

/**
 * Projects Component
 * 
 * Displays a list of projects with filtering and pagination.
 */
export default function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('Working');
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 8;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProjects();
  }, []);

  /**
   * Fetches projects data and sorts them by updated date.
   */
  const fetchProjects = async () => {
    setLoading(true);
    try {
      const projectsData = await projectService.getProjects();
      const sortedProjects = (projectsData.data || projectsData).sort((a, b) =>
        new Date(b.updated_at) - new Date(a.updated_at)
      );
      setProjects(sortedProjects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
    setLoading(false);
  };

  /**
   * Handles the filter change and resets the current page to 1.
   * @param {string} filter - The selected filter.
   */
  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1);
  };

  const filteredProjects = projects.filter((project) => {
    if (currentFilter === 'Archived') {
      return project.project_stage === 'Archived';
    } else {
      return project.project_stage !== 'Archived';
    }
  });

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const tabs = [
    { name: 'Working', current: currentFilter === 'Working', onClick: () => handleFilterChange('Working') },
    { name: 'Archived', current: currentFilter === 'Archived', onClick: () => handleFilterChange('Archived') },
  ];

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  /**
   * Handles the previous page click.
   */
  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  /**
   * Handles the next page click.
   */
  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * projectsPerPage + 1;
  const endResult = Math.min(startResult + projectsPerPage - 1, filteredProjects.length);

  return (
    <div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/home" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/home" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/projects" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Projects
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow-lg border border-gray-100 sm:px-6">
          <div className="">
            <h3 className="text-xl font-semibold text-gray-900 my-4">Projects</h3>
          </div>
          {/* <div> */}
            {/* <div className="sm:block">
              <nav className="flex items-center space-x-2" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={tab.onClick}
                    className={`rounded-md border px-3 py-2 text-sm font-medium w-24 h-10 flex justify-center items-center ${
                      tab.current ? 'bg-green-100 text-green-900' : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'
                    }`}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div> */}
          {/* </div> */}
          <div className="flex min-h-full flex-col">
            <div className="px-0">
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                      <thead>
                        <tr>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Project ID</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900" style={{ maxWidth: '15%' }}>Company</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Address</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Stage</th>
                          <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentProjects.length > 0 ? (
                          currentProjects.map((project) => (
                            <tr key={project.id} className="even:bg-gray-50">
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                                {project.id}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate" style={{ maxWidth: '15%' }}>
                                {project.company_name}
                              </td>

                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                                {`${project.city}, ${project.state}`}
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                                  project.project_stage === 'Archived' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                                }`}>
                                  {project.project_stage}
                                </span>
                              </td>
                              <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                                <Link to={`/projects/${project.id}`} className="text-blue-500 hover:text-blue-700">
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                              No Projects Yet
                            </td>
                          </tr>
                        )}
                        {currentProjects.length < 10 && [...Array(10 - currentProjects.length)].map((_, index) => (
                          <tr key={`empty-${index}`} className="even:bg-gray-50">
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                            <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500"></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <nav
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                      aria-label="Pagination"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                          <span className="font-medium">{filteredProjects.length}</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                          onClick={handlePreviousClick}
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Previous
                        </button>
                        <button
                          onClick={handleNextClick}
                          className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                        >
                          Next
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
