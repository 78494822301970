import http from "../../http-common";
import serviceUtil from "../../utils/service-util";  // Ensure the import path is correct

class AccountService {
    /**
     * Creates a new account.
     * @param {Object} data - Account data.
     * @returns {Promise} The server response.
     */
    async newAccount(data) {
        return await http.post("/accounts", { "account": data });
    }

    /**
     * Updates an existing account.
     * @param {Object} data - Updated account data.
     * @returns {Promise} The server response.
     */
    async updateAccount(data) {
        const id = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.patch(`/accounts/${id}`, { "account": data });
    }

    /**
     * Retrieves a list of all accounts.
     * @returns {Promise} The server response with the list of accounts.
     */
    async getAccounts() {
        return await http.get("/accounts");
    }

    /**
     * Retrieves a single account by its ID.
     * @param {string} [_id] - The ID of the account. If not provided, it will extract the ID from the current URL.
     * @returns {Promise} The server response with the account data.
     */
    async getAccount(_id) {
        const id = _id || await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/accounts/${id}`);
    }

    /**
     * Creates a checkout session.
     * @returns {Promise} The server response.
     */
    async createCheckoutSession() {
        return http.post("/create-checkout-session");
    }

    /**
     * Retrieves the setup intent for payment setup.
     * @returns {Promise} The server response.
     */
    async getSetupIntent() {
        return http.post("/create-setup-intent");
    }

    /**
     * Unsubscribes the user from their current subscription.
     * @returns {Promise} The server response.
     */
    async unsubscribeUser() {
        return http.patch("/unsubscribe");
    }

    /**
     * Reinstates the user's subscription.
     * @returns {Promise} The server response.
     */
    async reinstateUser() {
        return http.patch("/reinstate");
    }

    /**
     * Checks if the user's account is active.
     * @returns {Promise} The server response.
     */
    async checkAccountActivation() {
        return http.get(`/check_sales_partner_subscription`);
    }
}

const accountService = new AccountService();
export default accountService;
