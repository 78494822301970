import { useState, useEffect } from 'react';
import NewLinkModal from './NewLink.component';
import projectService from '../../services/project-services/projects.service';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/24/outline';

export default function ProjectTools({ projectId }) {
  const [isNewLinkModalOpen, setIsNewLinkModalOpen] = useState(false);
  const [links, setLinks] = useState([]);

  const fetchLinks = async () => {
    try {
      const response = await projectService.getLinks(projectId);
      setLinks(response.data);
    } catch (error) {
      console.error("Error fetching tools:", error);
      toast.error('Failed to fetch tools.');
    }
  };

  const deleteLink = async (linkId) => {
    try {
      await projectService.deleteLink(linkId);
      setLinks(links.filter(link => link.id !== linkId));
      toast.success('Tool deleted successfully!');
    } catch (error) {
      console.error("Error deleting tool:", error);
      toast.error('Failed to delete tool.');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchLinks();
  }, [projectId]);

  return (
    <>
      <NewLinkModal 
        isNewLinkModalOpen={isNewLinkModalOpen}
        setIsNewLinkModalOpen={setIsNewLinkModalOpen}
        fetchLinks={fetchLinks}
        toast={toast}
      />

      <div className="flex justify-center mt-5">
        <div className="w-full max-w-7xl">
          <div className="rounded-lg bg-white px-4 py-4 shadow-md border border-gray-100">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold text-gray-700 py-2">Project Links</h2>
              
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                  onClick={() => setIsNewLinkModalOpen(true)}
                >
                  Add Link
                </button>
              
            </div>
            <div className="mt-5 h-128 overflow-y-auto">
              {links.length > 0 && (
                <div className="space-y-4">
                  {links.map((link, index) => (
                    <div key={index} className="flex items-center bg-white shadow-md rounded-lg overflow-hidden border border-gray-300 hover:shadow-lg transition-shadow duration-200 ease-in-out p-2">
                      <div className="flex-grow">
                        <h5 className="text-sm font-bold text-gray-800 truncate">{link.link_type}</h5>
                        <a href={link.link_url} className="text-blue-600 hover:text-indigo-900" target="_blank" rel="noopener noreferrer">
                          {link.link_title}
                        </a>
                      </div>
                      <div className="flex space-x-4 ml-auto">
                        <button
                          onClick={() => deleteLink(link.id)}
                          className="btn-delete flex items-center text-red-500 hover:text-red-600 transition-colors duration-150 ease-in-out"
                        >
                          <TrashIcon className="w-6 h-6 md:h-4 md:w-4" />
                          <span className="hidden md:inline mx-2">Delete</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
