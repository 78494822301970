import CheckoutForm from "../components/website/Checkout.component";
import AppHeader from "../layout/AppHeader";

/**
 * UpgradeSubscription component renders a page for users to upgrade their subscription within the appHeader.
 * It includes the CheckoutForm component for handling the subscription upgrade process.
 *
 * @returns {JSX.Element} The UpgradeSubscription component.
 */
export default function UpgradeSubscription() {
    return (
        <>
            <AppHeader inner_content={
                <div className="mt-8">
                    <CheckoutForm />
                </div>
            }>
            </AppHeader>
        </>
    );
}