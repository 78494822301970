import { useLocation, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";

/**
 * Requires user to be authenticated. If not authenticated, redirects to login page.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if authenticated.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
export function RequireAuth({ children }) {
    let cookies = new Cookies();    
    let user  = cookies.get("authorization");
    let location = useLocation();

    if (!user) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

/**
 * Requires user to be not authenticated. If authenticated, redirects to dashboard.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if not authenticated.
 * @returns {JSX.Element} The children components or a redirect to dashboard page.
 */
export function RequireNoAuth({ children }) {
    let cookies = new Cookies();    
    let user  = cookies.get("authorization");
    let location = useLocation();

    if (user) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

/**
 * Requires user to have a specific account type. If not, redirects to login page.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if account type matches.
 * @param {string} props.requiredType - The required account type.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
function RequireAccountType({ children, requiredType }) {
    let cookies = new Cookies();    
    let accountType  = cookies.get("account_type");
    let location = useLocation();

    // Check both authentication and account type
    if (!accountType || accountType !== requiredType) {
        // Redirect to login if not authenticated or account type does not match
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

/**
 * Requires user to have a specific company role. If not, redirects to login page.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if company role matches.
 * @param {string} props.requiredRole - The required company role.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
function RequireCompanyRole({ children, requiredRole }) {
    let cookies = new Cookies();    
    let companyRole = cookies.get("company_role");
    let location = useLocation();

    // Check both authentication and company role
    if (!companyRole || companyRole !== requiredRole) {
        // Redirect to login if not authenticated or company role does not match
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

// Specific Account Types
/**
 * Requires user to have the 'referral_partner' account type.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if account type matches.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
export function RequireReferralPartner({ children }) {
    return <RequireAccountType children={children} requiredType="referral_partner" />;
}

/**
 * Requires user to have the 'full_access_admin' company role.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if company role matches.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
export function RequireAdmin({ children }) {
    return <RequireCompanyRole children={children} requiredRole="full_access_admin" />;
}

/**
 * Requires user to have the 'sales_partner' account type.
 * @param {Object} props - Component props.
 * @param {JSX.Element} props.children - The children components to render if account type matches.
 * @returns {JSX.Element} The children components or a redirect to login page.
 */
export function RequireSuninSalesPartner({ children }) {
    return <RequireAccountType children={children} requiredType="sales_partner" />;
}
