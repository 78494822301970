import React, { useEffect } from 'react';
import { ChevronLeftIcon} from '@heroicons/react/20/solid';

const videos = [];

function FinanceTraining({ onBack }) {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-5">
      <nav>
        <button onClick={onBack} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
          <ChevronLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          Back to Categories
        </button>
      </nav>
      <div className="bg-white border border-gray-200 shadow-xl rounded-xl p-8 mt-5 mb-10">
        <div className="text-center mb-12">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Solar Energy</h1>
          <p className="text-md text-gray-700 mx-auto max-w-xs">
            Discover key concepts and the latest in solar technology through our video series.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
          {videos.length > 0 ? (
            videos.map((video) => (
              <div key={video.id} className="flex flex-col md:flex-row lg:flex-row bg-white rounded-lg shadow overflow-hidden">
                <div className="md:w-1/3 lg:w-1/3">
                  <div className="aspect-w-16 aspect-h-9">
                    <iframe
                      src={`https://www.youtube.com/embed/${video.videoId}`}
                      title={video.title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full"
                    ></iframe>
                  </div>
                </div>
                <div className="flex-grow p-6">
                  <h5 className="text-xl font-semibold text-gray-900 mb-2">{video.title}</h5>
                  <p className="text-sm text-gray-600">{video.description}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-64">
              <h3 className="text-xl font-semibold text-gray-800">No Training Videos Available</h3>
              <p className="text-md text-gray-600">Check back later or contact support for more information.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default FinanceTraining;
