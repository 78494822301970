import Hero from "../../components/website/Hero";
import FrequentlyAskedQuestions from "../../components/website/FreequentlyAskedQuestions"
import AllInOnePlatform from "../../components/website/AllInOnePlatfrom.component"
import PlatformDemo from "../../components/website/PlatformDemo";          
import Pricing from "../../pages/website/Pricing";
import CaseStudy from "../../components/website/CaseStudy.component";

function IndexPage() {
    return (
      <>
        <Hero />
        <AllInOnePlatform />
        <PlatformDemo />
        <Pricing />
        {/* <CaseStudy/> */}
        <FrequentlyAskedQuestions />
      </>
    );
  }
  
export default IndexPage;