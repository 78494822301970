import { useState, useEffect } from 'react';
import adminService from '../../services/admin-services/admin.service';
import toast from 'react-hot-toast';

/**
 * AdminProjectStages Component
 * 
 * This component allows admin users to update the stages of a project.
 * 
 * @param {Object} props - Component properties
 * @param {string} props.projectId - The ID of the project
 */
export default function AdminProjectStages({ projectId, fetchProject}) {
  const steps = [
    { id: 'Project Initiation', name: 'Project Initiation' },
    { id: 'Presentation Ready', name: 'Presentation Ready' },
    { id: 'Financing and Documentation', name: 'Financing and Documentation' },
    { id: 'Site Survey and Finalization', name: 'Site Survey and Finalization' },
    { id: 'Project Completion', name: 'Project Completion' },
  ];

  const [currentStep, setCurrentStep] = useState('Project Initiation');

  useEffect(() => {
    fetchProjectStage();
  }, []);

  const fetchProjectStage = async () => {
    try {
      const response = await adminService.getProject(projectId);
      setCurrentStep(response.data.project_stage);
    } catch (error) {
      console.error('Error fetching project stage:', error);
      toast.error('Failed to fetch project stage.');
    }
  };

  const handleStageChange = async (selectedStepId) => {
    setCurrentStep(selectedStepId);
    try {
      await adminService.updateProject(projectId, { project_stage: selectedStepId });
      fetchProject();
      toast.success('Project stage updated successfully!');
    } catch (error) {
      console.error('Error updating project stage:', error);
      toast.error('Failed to update project stage.');
    }
  };

  const getStatus = (stepId) => {
    const stepIndex = steps.findIndex(step => step.id === stepId);
    const currentStepIndex = steps.findIndex(step => step.id === currentStep);
    if (stepIndex < currentStepIndex) {
      return 'complete';
    } else if (stepIndex === currentStepIndex) {
      return 'current';
    } else {
      return 'upcoming';
    }
  };

  return (
    <div className="container mx-auto mt-5">
      <div className="rounded-lg bg-white px-4 py-4 shadow-lg border border-gray-100">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold text-gray-700 py-2">Project Photos</h2>
        </div>
        <div className="space-y-4 mt-4">
          {steps.map((step, index) => {
            const status = getStatus(step.id);
            return (
              <div key={index} className="flex items-center justify-between p-2 border-b border-gray-200">
                <div className={`flex items-center justify-between w-full p-2 rounded-lg ${status === 'complete' ? 'bg-green-100' : status === 'current' ? 'bg-blue-100' : 'bg-gray-100'}`}>
                  <div>
                    <h5 className="text-lg font-medium text-gray-900">{step.name}</h5>
                    <p className={`text-sm ${status === 'complete' ? 'text-green-700' : status === 'current' ? 'text-blue-700' : 'text-gray-500'}`}>
                      Status: {status.charAt(0).toUpperCase() + status.slice(1)}
                    </p>
                  </div>
                  <button
                    type="button"
                    className={`ml-4 px-4 py-2 text-sm font-medium rounded-md focus:outline-none ${status === 'current' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700 hover:bg-gray-400'}`}
                    onClick={() => handleStageChange(step.id)}
                  >
                    {status === 'current' ? 'Current Stage' : 'Set as Current'}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
