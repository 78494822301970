import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, Bars3Icon, XMarkIcon, PlusIcon } from '@heroicons/react/20/solid';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';

import {
  HomeIcon,
  UsersIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ClipboardDocumentListIcon,
  BuildingOfficeIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';


import { useNavigate, Link, useLocation } from 'react-router-dom';
import authenticationService from '../services/authorization-services/auth.service';
import NotificationsPanel from '../components/NotificationsPanel';
import NewAccountModal from "../components/accounts/NewAccount.component";
import { User } from 'react-feather';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SidebarHeader({ inner_content }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isNotificationPanelOpen, setNotificationPanelOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [activeTab, setActiveTab] = useState('Home');

  useEffect(() => {
    authenticationService.getUserProfile()
      .then(response => {
        setUserName(`${response.first_name} ${response.last_name}`);
        setUserRole(response.account_type);
        setEmailVerified(response.email_verified_at != null);
      })
      .catch(error => {
        console.error('Failed to fetch user info:', error);
      });
  }, []);

  const logout = () => {
    authenticationService.logout()
      .then(() => {
        navigate('/login');
      })
      .catch((err) => {
        console.error(err);
        alert("Logout unsuccessful.");
      });
  };

  const navigation = [
    { name: 'Company Dashboard', href: '/admin', icon: HomeIcon },
    { name: 'Administrators', href: '/admin/administrator-management', icon: UserGroupIcon },
    { name: 'Members', href: '/admin/all-users', icon: UsersIcon },
    { name: 'Proposal Management', href: '/admin/proposal-management', icon: ClipboardDocumentListIcon },
    { name: 'All Accounts', href: '/admin/all-accounts', icon: BriefcaseIcon },
    { name: 'All Projects', href: '/admin/all-projects', icon: ChartBarIcon },
    { name: 'All Referrals', href: '/admin/all-referrals', icon: BuildingOfficeIcon },
  ].filter(Boolean);

  const isActive = (path) => location.pathname === path;

  const userNavigation = [
    { name: 'Profile', href: '/profile', action: () => {} },
    { name: 'Log out', href: '', action: logout },
  ];

  return (
    <>
      {isNotificationPanelOpen && (<NotificationsPanel setNotificationPanelOpen={setNotificationPanelOpen} />)}
      <NewAccountModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-100 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center justify-center">
                      <img className="mt-10 h-16 w-auto" src="/suninn_logo.png" alt="Logo" />
                    </div>

                    <nav className="flex mt-5 flex-1 flex-col">
                      <ul className="flex-1 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name} className="flex justify-center">
                            <Link
                              to={item.href}
                              className={classNames(
                                isActive(item.href) ? 'bg-gray-500 text-white border border-white' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-300',
                                'group flex gap-x-3 p-2.5 text-sm leading-6 font-semibold w-full pl-12 bg-gray-200 rounded-md'
                              )}
                              onClick={() => setSidebarOpen(false)}
                            >
                              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-64 lg:overflow-y-auto bg-gray-50 lg:pb-4 border-r border-gray-200">
          <div className="flex h-16 text-gray-900 shrink-0 items-center justify-center">
            <img className="mt-6 h-14 w-auto" src="/suninn_logo.png" alt="Logo" />
          </div>
          <nav className="mt-12">
            <ul className="flex flex-col space-y-2">
              {navigation.map((item) => (
                <li key={item.name} className="flex justify-center px-3">
                  <Link
                    to={item.href}
                    className={classNames(
                      isActive(item.href) ? 'bg-gray-500 text-white border border-white' : 'text-gray-600 hover:text-gray-900 hover:bg-gray-300',
                      'group flex gap-x-3 p-2.5 text-xs leading-6 font-semibold w-full pl-8 bg-gray-200 rounded-md'
                    )}
                  >
                    <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className="lg:pl-64">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-gray-50 px-4 shadow-md sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 w-full justify-end">
             
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <div className="user-icon mr-4 lg:hidden">
                      <User className="h-8 w-8 rounded-full bg-gray-50" />
                    </div>
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {userName}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            item.href ? (
                              <Link
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                {item.name}
                              </Link>
                            ) : (
                              <button
                                className={classNames(
                                  active ? 'bg-gray-50' : '',
                                  'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                                onClick={item.action}
                              >
                                {item.name}
                              </button>
                            )
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <main>
            <div className="px-0 py-0 sm:px-0 lg:px-0 lg:py-0">{inner_content}</div>
          </main>
        </div>
      </div>
    </>
  );
}
