import React, { useState, useEffect } from 'react';
import adminService from '../../services/admin-services/admin.service';
import { Link } from 'react-router-dom';

export default function UserModal({ userId, isOpen, onClose, refetchUsers }) {
  // User details
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  // Address Information
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  // Company Information
  const [companyRole, setCompanyRole] = useState('');
  const [accountType, setAccountType] = useState('');
  const [referredBy, setReferredBy] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [updatedAt, setUpdatedAt] = useState('');
  const [lastActivityAt, setLastActivityAt] = useState('');
  const [emailVerifiedAt, setEmailVerifiedAt] = useState('');

  // Billing / Stripe Information
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [stripeSubscriptionId, setStripeSubscriptionId] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [isInTrial, setIsInTrial] = useState('');
  const [trialEndDate, setTrialEndDate] = useState('');
  const [subscriptionEndDate, setSubscriptionEndDate] = useState('');

  const [loading, setLoading] = useState(false);

  // Projects, Accounts, Referrals
  const [projects, setProjects] = useState([]);
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(8);

  const [accounts, setAccounts] = useState([]);
  const [currentAccountPage, setCurrentAccountPage] = useState(1);
  const [accountsPerPage, setAccountsPerPage] = useState(8);

  const [referrals, setReferrals] = useState([]);
  const [currentReferralPage, setCurrentReferralPage] = useState(1);
  const [referralsPerPage, setReferralsPerPage] = useState(8);

  const [currentFilter, setCurrentFilter] = useState('Working');

  useEffect(() => {
    if (userId && isOpen) {
      const fetchUser = async () => {
        try {
          const response = await adminService.getUser(userId);
          const data = response.data;
  
          setEmail(data.email || '');
          setUserName(data.user_name || '');
          setFirstName(data.first_name || '');
          setLastName(data.last_name || '');
          setPhoneNumber(data.phone_number || '');
          setAddress1(data.address_1 || '');
          setAddress2(data.address_2 || '');
          setCity(data.city || '');
          setState(data.state || '');
          setZip(data.zip || '');
          setCompanyRole(data.company_role || '');
          setAccountType(data.account_type || '');
          setCreatedAt(data.created_at || 'Not available');
          setUpdatedAt(data.updated_at || 'Not available');
          setLastActivityAt(data.last_activity_at || 'Not available');
          setEmailVerifiedAt(data.email_verified_at ? 'True' : 'False');
          setReferredBy(data.referrer_name || '');
          setStripeCustomerId(data.stripe_customer_id || '');
          setStripeSubscriptionId(data.stripe_subscription_id || '');
          setSubscriptionStatus(data.subscription_status || '');
          setIsInTrial(data.is_in_trial || '');
          setTrialEndDate(data.trial_end_date || '');
          setSubscriptionEndDate(data.subscription_end_date || '');
  
          // Fetch user-specific data
          const referralsResponse = await adminService.getLeads(userId);
          setReferrals(referralsResponse.data || []);
  
          const accountsResponse = await adminService.getAccounts(userId);
          setAccounts(accountsResponse.data || []);
  
          const projectsResponse = await adminService.getProjects(userId);
          setProjects(projectsResponse.data || []);
  
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      };
  
      fetchUser();
    }
  }, [userId, isOpen]);
  

  if (!isOpen) return null;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updatedUser = {
        email,
        user_name: userName,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        address_1: address1,
        address_2: address2,
        city,
        state,
        zip,
        company_role: companyRole,
        account_type: accountType,
        referrer_name: referredBy,
      };

      await adminService.updateUser(userId, updatedUser);
      alert('User updated successfully');
      refetchUsers();
      onClose();
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating user');
    } finally {
      setLoading(false);
    }
  };

  const filteredProjects = projects.filter((project) => {
    if (currentFilter === 'Working') {
      return project.project_stage !== 'Archived';
    } else if (currentFilter === 'Archived') {
      return project.project_stage === 'Archived';
    }
    return true;
  });

  const indexOfLastProject = currentProjectPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const projectTotalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handleProjectPreviousClick = () => {
    setCurrentProjectPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleProjectNextClick = () => {
    setCurrentProjectPage((prevPage) => Math.min(prevPage + 1, projectTotalPages));
  };

  const projectStartResult = (currentProjectPage - 1) * projectsPerPage + 1;
  const projectEndResult = Math.min(projectStartResult + projectsPerPage - 1, filteredProjects.length);

  const indexOfLastAccount = currentAccountPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const currentAccounts = accounts.slice(indexOfFirstAccount, indexOfLastAccount);

  const accountTotalPages = Math.ceil(accounts.length / accountsPerPage);

  const handleAccountPreviousClick = () => {
    setCurrentAccountPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleAccountNextClick = () => {
    setCurrentAccountPage((prevPage) => Math.min(prevPage + 1, accountTotalPages));
  };

  const accountStartResult = (currentAccountPage - 1) * accountsPerPage + 1;
  const accountEndResult = Math.min(accountStartResult + accountsPerPage - 1, accounts.length);

  const indexOfLastReferral = currentReferralPage * referralsPerPage;
  const indexOfFirstReferral = indexOfLastReferral - referralsPerPage;
  const currentReferrals = referrals.slice(indexOfFirstReferral, indexOfLastReferral);

  const referralTotalPages = Math.ceil(referrals.length / referralsPerPage);

  const handleReferralPreviousClick = () => {
    setCurrentReferralPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleReferralNextClick = () => {
    setCurrentReferralPage((prevPage) => Math.min(prevPage + 1, referralTotalPages));
  };

  const referralStartResult = (currentReferralPage - 1) * referralsPerPage + 1;
  const referralEndResult = Math.min(referralStartResult + referralsPerPage - 1, referrals.length);

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-y-auto max-h-[calc(100vh-10rem)]">
            <div className="mt-2">
              {/* User Name and Contact Info */}
              <div className="mb-4">
                <h4 className="text-md font-semibold mb-2">Basic Information</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Last Name</label>
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Username</label>
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                    <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                </div>
              </div>

              {/* Address Information */}
              <div className="mb-4">
                <h4 className="text-md font-semibold mb-2">Address Information</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Address 1</label>
                    <input type="text" value={address1} onChange={(e) => setAddress1(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Address 2</label>
                    <input type="text" value={address2} onChange={(e) => setAddress2(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">City</label>
                    <input type="text" value={city} onChange={(e) => setCity(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">State</label>
                    <input type="text" value={state} onChange={(e) => setState(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Zip Code</label>
                    <input type="text" value={zip} onChange={(e) => setZip(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                </div>
              </div>

              {/* Company Information */}
              <div className="mb-4">
                <h4 className="text-md font-semibold mb-2">Company Information</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Account Type</label>
                    <select value={accountType} onChange={(e) => setAccountType(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm">
                      <option value="referral_partner">Referral Partner</option>
                      <option value="sales_partner">Sales Partner</option>
                      <option value="enterprize_partner">Enterprise Partner</option>
                      <option value="administrative_partner">Administrative Partner</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Company Role</label>
                    <select value={companyRole} onChange={(e) => setCompanyRole(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm">
                      <option value="">Select a role</option>
                      <option value="full_access_admin">full_access_admin</option>
                      <option value="project_engineer">project_engineer</option>
                      <option value="project_manager">project_manager</option>
                      <option value="human_resources_admin">human_resources_admin</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Referred By</label>
                    <input type="text" value={referredBy} onChange={(e) => setReferredBy(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm" />
                  </div>
                </div>
              </div>

              {/* Audit Information */}
              <div className="mb-4">
                <h4 className="text-md font-semibold mb-2">Audit Information</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Created At</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {createdAt ? new Date(createdAt).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Last Updated</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {updatedAt ? new Date(updatedAt).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Last Activity At</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {lastActivityAt ? new Date(lastActivityAt).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }) : 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Email Verified</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {emailVerifiedAt ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Billing / Stripe Information */}
              <div className="mb-4">
                <h4 className="text-md font-semibold mb-2">Billing & Stripe Information</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Stripe Customer ID</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {stripeCustomerId || 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Stripe Subscription ID</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {stripeSubscriptionId || 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Subscription Status</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {subscriptionStatus || 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Subscription Renewable Date</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {subscriptionEndDate ? new Date(subscriptionEndDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not Available'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Trial Status</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {isInTrial ? 'active' : 'inactive'}
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Trial End Date</label>
                    <div className="mt-1 p-2 bg-gray-100 rounded-md shadow-sm text-sm text-gray-600">
                      {trialEndDate ? new Date(trialEndDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not Available'}
                    </div>
                  </div>
                </div>
              </div>

              {/* Referrals */}
              <div className="rounded-lg bg-white mt-12">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold text-gray-900">Referrals</h3>
                </div>
                <div className="flex min-h-full flex-col">
                  <div className="px-0">
                    <div className="mt-4 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300 table-fixed">
                            <thead>
                              <tr>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Referral ID</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell overflow-x:hidden">Address</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Status</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {currentReferrals.length > 0 ? (
                                currentReferrals.map((referral) => (
                                  <tr key={referral.id} className="even:bg-gray-50">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{referral.id}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">{referral.company_name}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{referral.address}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${referral.status === 'Inactive' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                        {referral.status}
                                      </span>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                                      <Link to={"/admin/referrals/" + referral.id} className="text-blue-500 hover:text-blue-700">View</Link>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">No Referrals Yet</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                            <div className="hidden sm:block">
                              <p className="text-sm text-gray-700">Showing <span className="font-medium">{referralStartResult}</span> to <span className="font-medium">{referralEndResult}</span> of <span className="font-medium">{referrals.length}</span> results</p>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                              <button
                                onClick={handleReferralPreviousClick}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Previous
                              </button>
                              <button
                                onClick={handleReferralNextClick}
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Next
                              </button>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Projects */}
              <div className="rounded-lg bg-white mt-12">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold text-gray-900">Projects</h3>
                </div>
                <div className="flex min-h-full flex-col">
                  <div className="px-0">
                    <div className="mt-4 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300 table-fixed">
                            <thead>
                              <tr>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Project ID</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell overflow-x:hidden">Address</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Stage</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {currentProjects.length > 0 ? (
                                currentProjects.map((project) => (
                                  <tr key={project.id} className="even:bg-gray-50">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{project.id}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">{project.company_name}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{project.industry}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${project.project_stage === 'Archived' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                        {project.project_stage}
                                      </span>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                                      <Link to={"/admin/projects/" + project.id} className="text-blue-500 hover:text-blue-700">View</Link>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">No Projects Yet</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                            <div className="hidden sm:block">
                              <p className="text-sm text-gray-700">Showing <span className="font-medium">{projectStartResult}</span> to <span className="font-medium">{projectEndResult}</span> of <span className="font-medium">{filteredProjects.length}</span> results</p>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                              <button
                                onClick={handleProjectPreviousClick}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Previous
                              </button>
                              <button
                                onClick={handleProjectNextClick}
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Next
                              </button>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Accounts */}
              <div className="rounded-lg bg-white mt-12">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold text-gray-900">Accounts</h3>
                </div>
                <div className="flex min-h-full flex-col">
                  <div className="px-0">
                    <div className="mt-4 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                          <table className="min-w-full divide-y divide-gray-300 table-fixed">
                            <thead>
                              <tr>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Account ID</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell overflow-x:hidden">Address</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Status</th>
                                <th scope="col" className="w-1/5 px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                              </tr>
                            </thead>
                            <tbody className="bg-white">
                              {currentAccounts.length > 0 ? (
                                currentAccounts.map((account) => (
                                  <tr key={account.id} className="even:bg-gray-50">
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{account.id}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">{account.company_name}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">{account.address}</td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                                      <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${account.status === 'Inactive' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'}`}>
                                        {account.status}
                                      </span>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                                      <Link to={"/admin/accounts/" + account.id} className="text-blue-500 hover:text-blue-700">View</Link>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">No Accounts Yet</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          <nav className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
                            <div className="hidden sm:block">
                              <p className="text-sm text-gray-700">Showing <span className="font-medium">{accountStartResult}</span> to <span className="font-medium">{accountEndResult}</span> of <span className="font-medium">{accounts.length}</span> results</p>
                            </div>
                            <div className="flex flex-1 justify-between sm:justify-end">
                              <button
                                onClick={handleAccountPreviousClick}
                                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Previous
                              </button>
                              <button
                                onClick={handleAccountNextClick}
                                className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                              >
                                Next
                              </button>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sticky bottom-0">
            <button 
              onClick={handleSubmit} 
              type="button" 
              className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update User Information
            </button>
            <button 
              onClick={onClose} 
              type="button" 
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
