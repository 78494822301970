import React, { Fragment } from 'react';

const proposalStatus = [
  'Proposal requested and pending review',
  'Proposal accepted and assigned to engineer',
  'Proposal created and attached to project',
  'Proposal request rejected',
  'Revision requested and pending review',
  'Revision request accepted and assigned to engineer',
  'Revision completed and attached to project',
  'Revision request rejected',
];

export default function ProposalTicketCard({
  proposalTicket,
  formatDateTime,
  openRevisionForm,
  showRevisionForm,
  selectedTicketId,
  setShowRevisionForm,
  handleRequestRevision,
  dispatch,
  formState,
  setRevisionDetails,
  setRevisionType,
  revisionDetails,
  revisionType
}) {
  const getStatusColor = (status) => {
    switch (status) {
      case proposalStatus[0]:
        return 'bg-gray-300';
      case proposalStatus[1]:
        return 'bg-gray-500';
      case proposalStatus[2]:
        return 'bg-blue-500';
      case proposalStatus[3]:
        return 'bg-red-600';
      case proposalStatus[4]:
        return 'bg-gray-300';
      case proposalStatus[5]:
        return 'bg-gray-500';
      case proposalStatus[6]:
        return 'bg-blue-500';
      case proposalStatus[7]:
        return 'bg-red-600';
      default:
        return 'bg-gray-600';
    }
  };

  const getBarWidth = (status) => {
    switch (status) {
      case proposalStatus[0]:
        return 'w-1/3';
      case proposalStatus[1]:
        return 'w-2/3';
      case proposalStatus[2]:
        return 'w-full';
      case proposalStatus[3]:
        return 'w-full';
      case proposalStatus[4]:
        return 'w-1/2';
      case proposalStatus[5]:
        return 'w-3/4';
      case proposalStatus[6]:
        return 'w-full';
      case proposalStatus[7]:
        return 'w-full';
      default:
        return 'w-full';
    }
  };

  const handleRequestRevisionToggle = () => {
    if (showRevisionForm && selectedTicketId === proposalTicket.id) {
      setShowRevisionForm(false);
      setRevisionDetails('');
      setRevisionType('');
    } else {
      openRevisionForm(proposalTicket.id);
    }
  };

  const canRequestRevision = (status) => {
    const stagesWithRevisionButton = [
      'Revision completed and attached to project',
      'Revision rejected',
      'Proposal created and attached to project',
      'Proposal request rejected'
    ];
    return stagesWithRevisionButton.includes(status);
  };

  const showProposalLink = (status) => {
    const stagesWithLink = [
      'Revision completed and attached to project',
      'Proposal created and attached to project'
    ];
    return stagesWithLink.includes(status);
  };

  return (
    <div className="relative rounded-lg bg-white p-6 shadow-md border border-gray-100">
      <h5 className="text-lg font-semibold text-gray-800 mb-2">{proposalTicket.title}</h5>
      <p className="text-gray-700 mb-2"><strong>Company Name:</strong> {proposalTicket.company_name}</p>
      <p className="text-gray-700 mb-2"><strong>Company Address:</strong> {proposalTicket.company_address}</p>
      <p className="text-gray-700 mb-2"><strong>Current Status:</strong> {proposalTicket.status}</p>
      <div className="mt-4">
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className={`h-2.5 rounded-full ${getStatusColor(proposalTicket.status)} ${getBarWidth(proposalTicket.status)}`}></div>
        </div>
      </div>
      {canRequestRevision(proposalTicket.status) && !showRevisionForm && selectedTicketId !== proposalTicket.id && (
        <div className="absolute top-4 right-4">
          <button
            className="bg-blue-400 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md"
            onClick={handleRequestRevisionToggle}
          >
            Request Revision
          </button>
        </div>
      )}
      {showRevisionForm && selectedTicketId === proposalTicket.id ? (
        <div className="mt-4">
          <div className="mb-4">
            <label className="text-gray-700">Revision Type:</label>
            <select
              className="block w-full rounded-md border border-gray-300 shadow-sm p-3"
              value={revisionType}
              onChange={(e) => setRevisionType(e.target.value)}
            >
              <option value="">Select revision type</option>
              <option value="Request a separate proposal design">Request a separate proposal design</option>
              <option value="Request a revision to the original proposal">Request a revision to the original proposal</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="text-gray-700">Revision Details:</label>
            <textarea
              className="block w-full rounded-md border border-gray-300 shadow-sm p-3"
              rows="4"
              value={revisionDetails}
              onChange={(e) => setRevisionDetails(e.target.value)}
            ></textarea>
          </div>
          <div className="flex justify-between">
            <button
              type="button"
              className="mt-2 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleRequestRevisionToggle}
            >
              Cancel
            </button>
            <button
              type="button"
              className="mt-2 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleRequestRevision}
            >
              Submit Revision Request
            </button>
          </div>
        </div>
      ) : (
        <div className="px-4 py-6 sm:px-6 lg:px-8">
          <nav className="flex flex-col" aria-label="History">
            <ol role="list" className="space-y-6">
              {proposalTicket.proposal_ticket_histories &&
                proposalTicket.proposal_ticket_histories
                  .sort((a, b) => new Date(a.event_date) - new Date(b.event_date))
                  .map((historyItem, idx) => (
                    <li key={idx} className="relative">
                      <div className="flex items-start space-x-4">
                        <div className={`flex-shrink-0 w-4 h-4 rounded-full ${getStatusColor(historyItem.status)}`}></div>
                        <div className="ml-4">
                          <div className="text-sm font-semibold" style={{ color: getStatusColor(historyItem.status).replace('bg-', 'text-') }}>
                            Stage: {historyItem.status}
                          </div>
                          <div className="text-sm text-gray-600">
                            <strong>Date:</strong> {formatDateTime(historyItem.event_date)}
                          </div>
                          <div className="text-sm text-gray-600">
                            <strong>Action by:</strong> {historyItem.action_taken_by}
                          </div>
                          {historyItem.rejection_reason && (
                            <div className="text-sm text-gray-600">
                              <strong>Rejection Reason:</strong> {historyItem.rejection_reason}
                            </div>
                          )}
                          {historyItem.rejection_specific && (
                            <div className="text-sm text-gray-600">
                              <strong>Rejection Specific:</strong> {historyItem.rejection_specific}
                            </div>
                          )}
                          <div className="text-sm text-gray-600">
                            <strong>Details:</strong> {historyItem.notes}
                          </div>
                          {showProposalLink(historyItem.status) && historyItem.proposal_link && (
                            <div className="text-sm text-blue-500 hidden md:table-cell">
                              <strong>Proposal Link:</strong> <a href={historyItem.proposal_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{historyItem.proposal_link}</a>
                            </div>
                          )}
                        </div>
                      </div>
                      {idx < proposalTicket.proposal_ticket_histories.length - 1 && (
                        <div className="ml-2 h-full border-l-2 border-gray-300"></div>
                      )}
                    </li>
                  ))}
            </ol>
          </nav>
        </div>
      )}
    </div>
  );
}
