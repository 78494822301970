import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import OvercomingObjectionsTraining from '../../components/training/OvercomingObjections.component.jsx';
import ProspectingTraining from '../../components/training/Prospecting.component';
import ApproachingTraining from '../../components/training/Approaching.component';
import ClosingTraining from '../../components/training/Closing.component';
import PostSaleTraining from '../../components/training/PostSale.component';
import RegulationTraining from '../../components/training/Regulation.component';
import FinanceTraining from '../../components/training/Financing.component.jsx';
import GettingStarted from '../../components/training/GettingStarted.component.jsx';
import SolarTraining from '../../components/training/Solar.component';
import PlatformTraining from '../../components/training/Platform.component';

const categories = [
  { name: 'Platform', imgUrl: 'images/Screenshot 2024-03-11 at 4.02.25 PM.png', description: 'Guided tour to leverage our platform’s features for maximum productivity.' },
  { name: 'Previous Trainings', imgUrl: 'images/AdobeStock_384781663.jpeg', description: 'Coming soon...' },
];

function Education() {

  
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const handleBack = () => {
    setSelectedCategory(null);  // Reset the category to show all categories again
  };

  switch (selectedCategory) {
    case 'Getting Started': return <GettingStarted onBack={handleBack} />;
    case 'Platform': return <PlatformTraining onBack={handleBack} />;
    // case 'Best Practices': return <BestPracticesTraining onBack={handleBack} />;
    case 'Solar': return <SolarTraining onBack={handleBack} />;
    // case 'Prospecting': return <ProspectingTraining onBack={handleBack} />;
    // case 'Approaching': return <ApproachingTraining onBack={handleBack} />;
    // case 'Closing': return <ClosingTraining onBack={handleBack} />;
    // case "Overcoming Objections": return <OvercomingObjectionsTraining onBack={handleBack} />;
    // case 'Post-Sale': return <PostSaleTraining onBack={handleBack} />;
    // case 'Financing': return <FinanceTraining onBack={handleBack} />;
    // case 'Regulation': return <RegulationTraining onBack={handleBack} />;
    default: break;
  }

  return (



    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-4">

        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/home" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/home" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/sunin-school" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Training
                </Link>
              </div>
            </li>
          </ol>
        </nav>
    

      {/* Main Content Area */}
      <div className="bg-white border border-gray-100 shadow-lg rounded-lg px-5 py-10 mt-4 mb-10">
        <div className="text-center px-4 py-6 mb-4">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Complete Education</h1>
          <p className="text-md text-gray-700 mx-auto leading-relaxed max-w-xl">
            Access a wide array of educational content and training materials designed to help you understand and apply key concepts in your field.
          </p>
        </div>
        <div className="px-4 py-12">
          <div className="grid grid-cols-1 sm:grid-cols-2  gap-4">
            {categories.map((category, index) => (
              <div key={index}
                onClick={() => setSelectedCategory(category.name)}
                className="flex flex-col items-center bg-white border border-gray-200 shadow-sm rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-300 ease-in-out cursor-pointer">
                <div className="aspect-w-16 aspect-h-9 w-full overflow-hidden">
                  <img src={category.imgUrl} alt={category.name} className="w-full h-full object-cover transform transition duration-300 hover:scale-110" />
                </div>
                <div className="p-4 text-center">
                  <h5 className="text-lg font-semibold text-gray-900">{category.name}</h5>
                  <p className="text-sm text-gray-600">{category.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Education;
