import http from "../../http-common";
import serviceUtil from "../../utils/service-util";  // Ensure the import path is correct

class LeadService {
    // ------------- LEADS SECTION ------------- //

    /**
     * Creates a new lead.
     * @param {Object} data - Lead data.
     * @returns {Promise} The server response.
     */
    async newLead(data) {
        return await http.post("/leads", { "lead": data });
    }

    /**
     * Updates an existing lead.
     * @param {Object} data - Updated lead data.
     * @returns {Promise} The server response.
     */
    async updateLead(data) {
        const id = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.patch(`/leads/${id}`, { "lead": data });
    }

    /**
     * Retrieves a list of all leads.
     * @returns {Promise} The server response with the list of leads.
     */
    async getLeads() {
        return await http.get("/leads");
    }

    /**
     * Retrieves a single lead by its ID.
     * @returns {Promise} The server response with the lead data.
     */
    async getLead() {
        const leadId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/leads/${leadId}`);
    }

    // ------------- FILES SECTION ------------- //

    /**
     * Uploads files associated with a lead.
     * @param {string} leadId - The ID of the lead.
     * @param {Object} filesData - Files data.
     * @returns {Promise} The server response.
     */
    async uploadLeadFiles(leadId, filesData) {
        try {
            const response = await http.post(`/leads/${leadId}/lead_files`, filesData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return response;
        } catch (error) {
            console.error('Failed to upload lead files:', error);
            throw error;
        }
    }

    /**
     * Retrieves files associated with a lead.
     * @returns {Promise} The server response with the list of files.
     */
    async getFiles() {
        const leadId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/leads/${leadId}/lead_files`);
    }
}

const leadService = new LeadService();
export default leadService;
