import React, { useEffect, useState, Fragment } from 'react';
import adminService from '../../services/admin-services/admin.service';
import toast from 'react-hot-toast';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import ProposalTicketCardWithAdminModal from '../../components/admin/ProposalModal.component';

const proposalStages = [
  'Proposal requested and pending review',
  'Proposal accepted and assigned to engineer',
  'Proposal created and attached to project',
  'Proposal request rejected',
  'Revision requested and pending review',
  'Revision request accepted and assigned to engineer',
  'Revision completed and attached to project',
  'Revision request rejected'
];

const getStatusColor = (status) => {
  switch (status) {
    case 'Proposal requested and pending review':
    case 'Revision requested and pending review':
      return 'bg-yellow-50';
    case 'Proposal accepted and assigned to engineer':
      return 'bg-blue-50';
    case 'Proposal created and attached to project':
    case 'Revision completed and attached to project':
      return 'bg-green-50';
    case 'Proposal request rejected':
    case 'Revision request rejected':
      return 'bg-red-50';
    default:
      return '';
  }
};

const formatDateTime = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  return new Date(dateString).toLocaleString('en-US', options);
};

export default function ProposalTicketingManagement() {
  const [proposals, setProposals] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [details, setDetails] = useState('');
  const [newStage, setNewStage] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionSpecific, setRejectionSpecific] = useState('');
  const [engineerName, setEngineerName] = useState('');
  const [proposalLinkTitle, setProposalLinkTitle] = useState('');
  const [proposalLinkUrl, setProposalLinkUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [missingItems, setMissingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const proposalsPerPage = 8;

  useEffect(() => {
    fetchProposals();
  }, [currentPage]);

  const fetchProposals = async () => {
    setLoading(true);
    try {
      const response = await adminService.getProposals(currentPage, proposalsPerPage);
      if (response && response.data) {
        setProposals(response.data);
      } else {
        console.error('Failed to fetch proposals, response or response data is undefined');
      }
    } catch (error) {
      console.error('Failed to fetch proposals:', error);
      toast.error('Failed to fetch proposals.');
    }
    setLoading(false);
  };

  const handleSelectProposal = (proposal) => {
    setSelectedProposal(proposal);
    setIsModalOpen(true);
  };

  const handleUpdateProposal = async () => {
    if (!newStage) {
      toast.error('Please select a new stage.');
      return;
    }

    if (newStage === 'Proposal accepted and assigned to engineer' && !engineerName) {
      toast.error('Please enter the engineer\'s name.');
      return;
    }

    const updatedProposal = {
      status: newStage,
      admin_notes: details,
      engineer_name: newStage === 'Proposal accepted and assigned to engineer' ? engineerName : '',
      rejection_reason: ['Proposal request rejected'].includes(newStage) ? rejectionReason : '',
      rejection_specific: ['Proposal request rejected'].includes(newStage) ? rejectionSpecific : '',
      proposal_links: ['Proposal created and attached to project'].includes(newStage) ? [{ title: proposalLinkTitle, url: proposalLinkUrl }] : []
    };

    try {
      await adminService.updateProposal(selectedProposal.id, updatedProposal);
      toast.success('Proposal updated successfully!');
      resetForm();
      fetchProposals();
      closeModal();
    } catch (error) {
      console.error('Error updating proposal:', error);
      toast.error('Failed to update proposal.');
    }
  };

  const resetForm = () => {
    setSelectedProposal(null);
    setDetails('');
    setNewStage('');
    setRejectionReason('');
    setRejectionSpecific('');
    setEngineerName('');
    setProposalLinkTitle('');
    setProposalLinkUrl('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getStageOptions = (currentStatus) => {
    if (proposalStages.includes(currentStatus)) {
      switch (currentStatus) {
        case 'Proposal requested and pending review':
        case 'Revision requested and pending review':
          return ['Proposal accepted and assigned to engineer', 'Proposal request rejected', 'Revision request accepted and assigned to engineer', 'Revision request rejected'];
        case 'Proposal accepted and assigned to engineer':
        case 'Revision request accepted and assigned to engineer':
          return ['Proposal created and attached to project', 'Revision completed and attached to project'];
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  const totalPages = Math.ceil(proposals.length / proposalsPerPage);
  const filteredProposals = proposals.filter(proposal => !['Proposal request rejected', 'Proposal created and attached to project', 'Revision request rejected', 'Revision completed and attached to project'].includes(proposal.status));

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <nav className="sm:hidden mt-5" aria-label="Back">
          <Link to="/admin" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Admin Home
          </Link>
        </nav>
        <nav className="hidden sm:flex mt-5" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/admin" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    Admin Home
                </Link>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <Link to="/admin/proposal-management" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Proposal Management
                </Link>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 mt-5">
        <div className="w-full max-w-7xl">
          <div className="rounded-lg bg-white px-4 py-4 shadow-lg border border-gray-100">
            <h2 className="text-2xl font-semibold text-gray-700 p-2">Proposal Requests</h2>
            <div className="overflow-x-auto">
              {filteredProposals.length === 0 ? (
                <div className="flex items-center justify-center py-20">
                  <div className="text-center bg-gray-100 p-6 rounded-lg shadow-md">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No Active Proposals</h3>
                    <p className="mt-1 text-sm text-gray-500">There are currently no active proposal tickets. Please check back later.</p>
                  </div>
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden md:table-cell">Proposal ID</th>
                      <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                      <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">Status</th>
                      <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredProposals
                      .slice((currentPage - 1) * proposalsPerPage, currentPage * proposalsPerPage)
                      .map(proposal => (
                        <Fragment key={proposal.id}>
                          <tr className={`${getStatusColor(proposal.status)} even:bg-gray-50`}>
                            <td className="px-3 py-4 text-sm text-gray-500 hidden md:table-cell">{proposal.id}</td>
                            <td className="px-3 py-4 text-sm text-gray-900">{proposal.company_name}</td>
                            <td className="px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">{proposal.status}</td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              <button
                                onClick={() => handleSelectProposal(proposal)}
                                className="text-blue-500 hover:text-blue-700"
                              >
                                Manage
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
            <div className="flex justify-between items-center py-3">
              <button
                onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Previous
              </button>
              <span className="text-sm text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
                className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Next
              </button>
            </div>
          </div>

          {/* Proposal Details Modal */}
          {selectedProposal && (
            <ProposalTicketCardWithAdminModal
              isOpen={isModalOpen}
              onClose={closeModal}
              proposalTicket={selectedProposal}
              formatDateTime={formatDateTime}
              openRevisionForm={() => console.log('Open revision form')} // Placeholder function
              showRevisionForm={false}
              selectedTicketId={selectedProposal.id}
              setShowRevisionForm={() => console.log('Set show revision form')} // Placeholder function
              handleRequestRevision={() => console.log('Handle request revision')} // Placeholder function
              dispatch={() => console.log('Dispatch')} // Placeholder function
              formState={{}}
              setRevisionDetails={(details) => console.log('Set revision details:', details)} // Placeholder function
              setRevisionType={(type) => console.log('Set revision type:', type)} // Placeholder function
              revisionDetails=""
              revisionType=""
              details={details}
              setDetails={setDetails}
              newStage={newStage}
              setNewStage={setNewStage}
              rejectionReason={rejectionReason}
              setRejectionReason={setRejectionReason}
              rejectionSpecific={rejectionSpecific}
              setRejectionSpecific={setRejectionSpecific}
              engineerName={engineerName}
              setEngineerName={setEngineerName}
              proposalLinkTitle={proposalLinkTitle}
              setProposalLinkTitle={setProposalLinkTitle}
              proposalLinkUrl={proposalLinkUrl}
              setProposalLinkUrl={setProposalLinkUrl}
              handleUpdateProposal={handleUpdateProposal}
              loading={loading}
              missingItems={missingItems}
              project={{}}
            />
          )}
        </div>
      </div>
    </>
  );
}
