import React, { useEffect, useState, Fragment, useReducer } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import adminService from '../../services/admin-services/admin.service';
import projectService from '../../services/project-services/projects.service';
import authService from '../../services/authorization-services/auth.service';
import toast from 'react-hot-toast';

const proposalStages = [
  'Proposal requested and pending review',
  'Proposal accepted and assigned to engineer',
  'Proposal created and attached to project',
  'Proposal request rejected'
];

const revisionStages = [
  'Revision requested and pending review',
  'Revision request accepted and assigned to engineer',
  'Revision completed and attached to project',
  'Revision rejected'
];

const proposalStatus = [
  ...proposalStages,
  ...revisionStages
];

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value };
    case 'SET_STATE':
      return { ...action.state };
    case 'RESET':
      return { ...action.initialState };
    default:
      return state;
  }
};

export default function ProposalTicketingManagement({ project, fetchProject }) {
  const [proposals, setProposals] = useState([]);
  const [selectedProposalId, setSelectedProposalId] = useState(null);
  const [details, setDetails] = useState('');
  const [newStage, setNewStage] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionSpecific, setRejectionSpecific] = useState('');
  const [engineerName, setEngineerName] = useState('');
  const [proposalLinkTitle, setProposalLinkTitle] = useState('');
  const [proposalLinkUrl, setProposalLinkUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [missingItems, setMissingItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const proposalsPerPage = 8;

  const initialFormState = {
    details: '',
    actionTakenBy: '',
    revisionDetails: '',
    revisionType: '',
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const [proposalTicketSubmitted, setProposalTicketSubmitted] = useState(false);
  const [proposalTickets, setProposalTickets] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showRevisionForm, setShowRevisionForm] = useState(false);
  const [detailsValid, setDetailsValid] = useState(true);
  const [actionTakenByValid, setActionTakenByValid] = useState(true);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  useEffect(() => {
    fetchProposals();
    fetchProposalTickets(project.id);
  }, [project.id, currentPage]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await authService.getUserProfile();
        dispatch({ type: 'SET_FIELD', field: 'actionTakenBy', value: `${userProfile.first_name} ${userProfile.last_name}` });
      } catch (error) {
        console.error('Error fetching user profile:', error);
        toast.error('Failed to fetch user profile.');
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const savedFormState = localStorage.getItem('formState');
    if (savedFormState) {
      dispatch({ type: 'SET_STATE', state: JSON.parse(savedFormState) });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('formState', JSON.stringify(formState));
  }, [formState]);

  const fetchProposals = async () => {
    setLoading(true);
    try {
      const response = await adminService.getProposals(project.id, currentPage, proposalsPerPage);
      if (response && response.data) {
        setProposals(response.data);
      } else {
        console.error('Failed to fetch proposals, response or response data is undefined');
      }
    } catch (error) {
      console.error('Failed to fetch proposals:', error);
      toast.error('Failed to fetch proposals.');
    }
    setLoading(false);
  };

  const fetchProposalTickets = async (projectId) => {
    setLoading(true);
    try {
      const response = await projectService.getProposalTickets(projectId);
      const ticketsWithHistories = await Promise.all(response.data.map(async (ticket) => {
        const historyResponse = await projectService.getProposalTicketHistories(projectId, ticket.id);
        return { ...ticket, proposal_ticket_histories: historyResponse.data };
      }));
      setProposalTickets(ticketsWithHistories);
    } catch (error) {
      console.error('Error fetching proposal tickets:', error);
      toast.error('Failed to fetch proposal tickets.');
    }
    setLoading(false);
  };

  const handleSelectProposal = (proposalId) => {
    setSelectedProposalId(selectedProposalId === proposalId ? null : proposalId);
  };

  const handleUpdateProposal = async () => {
    if (!newStage) {
      toast.error('Please select a new stage.');
      return;
    }

    if (newStage === 'Proposal accepted and assigned to engineer' && !engineerName) {
      toast.error('Please enter the engineer\'s name.');
      return;
    }

    const updatedProposal = {
      status: newStage,
      admin_notes: details,
      engineer_name: newStage === 'Proposal accepted and assigned to engineer' ? engineerName : '',
      rejection_reason: ['Proposal request rejected', 'Revision rejected'].includes(newStage) ? rejectionReason : '',
      rejection_specific: ['Proposal request rejected', 'Revision rejected'].includes(newStage) ? rejectionSpecific : '',
      proposal_links: ['Proposal created and attached to project', 'Revision completed and attached to project'].includes(newStage) ? [{ title: proposalLinkTitle, url: proposalLinkUrl }] : []
    };

    try {
      await adminService.updateProposal(selectedProposalId, updatedProposal);
      toast.success('Proposal updated successfully!');
      resetForm();
      fetchProject();
      fetchProposals();
    } catch (error) {
      console.error('Error updating proposal:', error);
      toast.error('Failed to update proposal.');
    }
  };

  const resetForm = () => {
    setSelectedProposalId(null);
    setDetails('');
    setNewStage('');
    setRejectionReason('');
    setRejectionSpecific('');
    setEngineerName('');
    setProposalLinkTitle('');
    setProposalLinkUrl('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getRowClass = (status) => {
    switch (status) {
      case proposalStages[0]:
        return 'bg-yellow-50';
      case proposalStages[1]:
        return 'bg-blue-50';
      case proposalStages[2]:
        return 'bg-green-50';
      case proposalStages[3]:
        return 'bg-red-50';
      case revisionStages[0]:
        return 'bg-yellow-50';
      case revisionStages[1]:
        return 'bg-blue-50';
      case revisionStages[2]:
        return 'bg-green-50';
      case revisionStages[3]:
        return 'bg-red-50';
      default:
        return '';
    }
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const totalPages = Math.ceil(proposals.length / proposalsPerPage);

  return (
    <div className="flex justify-center mt-5">
      <div className="w-full max-w-7xl">
        <div className="rounded-lg bg-white px-4 py-4 shadow-lg border border-gray-100">
          <h2 className="text-2xl font-semibold text-gray-700 p-2">Proposal Requests</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Proposal ID</th>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Company</th>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Status</th>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody>
                {proposals.slice((currentPage - 1) * proposalsPerPage, currentPage * proposalsPerPage).map(proposal => (
                  <Fragment key={proposal.id}>
                    <tr className={`${getRowClass(proposal.status)} even:bg-gray-50`}>
                      <td className="px-3 py-4 text-sm text-gray-500">{proposal.id}</td>
                      <td className="px-3 py-4 text-sm text-gray-900 truncate">{proposal.company_name}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">{proposal.status}</td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        <button
                          onClick={() => handleSelectProposal(proposal.id)}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                    {selectedProposalId === proposal.id && (
                      <tr className="bg-white">
                        <td colSpan="4" className="px-6 py-4">
                          <div className="space-y-4">
                            <ProjectDetails project={project} />
                            <div className="space-y-4">
                              <div>
                                <label className="block text-sm font-medium text-gray-700">New Stage</label>
                                <select
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  value={newStage}
                                  onChange={(e) => setNewStage(e.target.value)}
                                >
                                  <option value="">Select a stage</option>
                                  {proposalStatus.map((stage) => (
                                    <option key={stage} value={stage}>{stage}</option>
                                  ))}
                                </select>
                              </div>
                              {newStage === 'Proposal accepted and assigned to engineer' && (
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">Engineer's Name</label>
                                  <input
                                    type="text"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={engineerName}
                                    onChange={(e) => setEngineerName(e.target.value)}
                                  />
                                </div>
                              )}
                              {['Proposal request rejected', 'Revision rejected'].includes(newStage) && (
                                <>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">Rejection Reason</label>
                                    <select
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      value={rejectionReason}
                                      onChange={(e) => setRejectionReason(e.target.value)}
                                    >
                                      <option value="">Select a reason</option>
                                      <option value="incorrect information">Incorrect Information</option>
                                      <option value="missing information">Missing Information</option>
                                      <option value="not feasible">Not Feasible</option>
                                      <option value="out of scope">Out of Scope</option>
                                    </select>
                                  </div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">Rejection Specific</label>
                                    <select
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      value={rejectionSpecific}
                                      onChange={(e) => setRejectionSpecific(e.target.value)}
                                    >
                                      <option value="">Select a specific field</option>
                                      <option value="utility_company">Utility Company</option>
                                      <option value="meter_number">Meter Number</option>
                                      <option value="number_of_meters">Number of Meters</option>
                                      <option value="main_panel_amperage">Main Panel Amperage</option>
                                      <option value="power_bill_received">Power Bill Received</option>
                                      <option value="monthly_kilowatt_amount">Monthly Kilowatt Amount</option>
                                      <option value="annual_kilowatt_amount">Annual Kilowatt Amount</option>
                                      <option value="average_monthly_power_bill_cost">Average Monthly Power Bill Cost</option>
                                      <option value="type_of_roof">Type of Roof</option>
                                      <option value="age_of_roof">Age of Roof</option>
                                      <option value="address_1">Address Line 1</option>
                                      <option value="city">City</option>
                                      <option value="state">State</option>
                                      <option value="zip">Zip</option>
                                      <option value="company_name">Company Name</option>
                                      <option value="utility_account_holder">Utility Account Holder</option>
                                      <option value="utility_account_number">Utility Account Number</option>
                                      <option value="shade_interference">Shade Interference</option>
                                    </select>
                                  </div>
                                </>
                              )}
                              {newStage === 'Revision request accepted and assigned to engineer' && (
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">Engineer's Name</label>
                                  <input
                                    type="text"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={engineerName}
                                    onChange={(e) => setEngineerName(e.target.value)}
                                  />
                                </div>
                              )}
                              {['Proposal created and attached to project', 'Revision completed and attached to project'].includes(newStage) && (
                                <>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">Proposal Link Title</label>
                                    <input
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      value={proposalLinkTitle}
                                      onChange={(e) => setProposalLinkTitle(e.target.value)}
                                    />
                                  </div>
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">Proposal Link URL</label>
                                    <input
                                      type="text"
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                      value={proposalLinkUrl}
                                      onChange={(e) => setProposalLinkUrl(e.target.value)}
                                    />
                                  </div>
                                </>
                              )}
                              <div>
                                <label className="block text-sm font-medium text-gray-700">Details/Comments</label>
                                <textarea
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                  rows="3"
                                  value={details}
                                  onChange={(e) => setDetails(e.target.value)}
                                ></textarea>
                              </div>
                              <button
                                onClick={handleUpdateProposal}
                                disabled={loading}
                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                              >
                                Update Proposal
                              </button>
                            </div>
                            <ProposalHistories proposalId={selectedProposalId} formatDateTime={formatDateTime} projectId={project.id} />
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center py-3">
            <button
              onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
              className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Previous
            </button>
            <span className="text-sm text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : totalPages)}
              className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
            >
              Next
            </button>
          </div>
        </div>

        <Transition show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Missing Required Fields
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Please complete the following required fields before updating the proposal:
                          </p>
                          <ul className="list-disc list-inside text-sm text-gray-500 mt-2">
                            {missingItems.map((item, index) => (
                              <li key={index}>{item.replace(/_/g, ' ')}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={closeModal}
                      >
                        Okay
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

function ProjectDetails({ project }) {
  return (
    <>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Project ID:</strong> {project.id}</p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Company Name:</strong> {project.company_name}</p>
      </div>
      <div className="mb-4">
        <p className="text-gray-700"><strong>Street Address:</strong> {project.address_1} {project.address_2} {project.city}, {project.state} {project.zip}</p>
      </div>
    </>
  );
}

function ProposalHistories({ proposalId, formatDateTime, projectId }) {
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const fetchHistories = async () => {
      try {
        const response = await projectService.getProposalTicketHistories(projectId, proposalId);
        setHistories(response.data);
      } catch (error) {
        console.error('Error fetching proposal ticket histories:', error);
        toast.error('Failed to fetch proposal ticket histories.');
      }
    };

    if (proposalId) {
      fetchHistories();
    }
  }, [proposalId, projectId]);

  const getStatusColor = (status) => {
    switch (status) {
      case proposalStatus[0]:
        return 'bg-yellow-300';
      case proposalStatus[1]:
        return 'bg-blue-500';
      case proposalStatus[2]:
        return 'bg-green-500';
      case proposalStatus[3]:
        return 'bg-red-600';
      case proposalStatus[4]:
        return 'bg-yellow-300';
      case proposalStatus[5]:
        return 'bg-purple-600';
      case proposalStatus[6]:
        return 'bg-green-500';
      case proposalStatus[7]:
        return 'bg-red-600';
      default:
        return 'bg-gray-600';
    }
  };

  return (
    <div className="px-4 py-6 sm:px-6 lg:px-8">
      <nav className="flex flex-col" aria-label="History">
        <ol className="space-y-6">
          {histories.sort((a, b) => new Date(a.event_date) - new Date(b.event_date)).map((historyItem, idx) => (
            <li key={idx} className="relative">
              <div className="flex items-start space-x-4">
                <div className={`flex-shrink-0 w-4 h-4 rounded-full ${getStatusColor(historyItem.status)}`}></div>
                <div className="ml-4">
                  <div className="text-sm font-semibold" style={{ color: getStatusColor(historyItem.status).replace('bg-', 'text-') }}>
                    Stage: {historyItem.status}
                  </div>
                  <div className="text-sm text-gray-600">
                    <strong>Date:</strong> {formatDateTime(historyItem.event_date)}
                  </div>
                  <div className="text-sm text-gray-600">
                    <strong>Name:</strong> {historyItem.action_taken_by}
                  </div>
                  {historyItem.rejection_reason && (
                    <div className="text-sm text-gray-600">
                      <strong>Rejection Reason:</strong> {historyItem.rejection_reason}
                    </div>
                  )}
                  {historyItem.rejection_specific && (
                    <div className="text-sm text-gray-600">
                      <strong>Rejection Specific:</strong> {historyItem.rejection_specific}
                    </div>
                  )}
                  <div className="text-sm text-gray-600">
                    <strong>Details:</strong> {historyItem.notes}
                  </div>
                  {historyItem.proposal_link && (
                    <div className="text-sm text-blue-500">
                      <strong>Proposal Link:</strong> <a href={historyItem.proposal_link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">{historyItem.proposal_link}</a>
                    </div>
                  )}
                </div>
              </div>
              {idx < histories.length - 1 && (
                <div className="ml-2 h-full border-l-2 border-gray-300"></div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}