import React from 'react';
import Footer from './Footer';
import SidebarHeader from './AppHeader'; // Ensure the import is correct, based on your file naming
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
  return (
 
      <SidebarHeader inner_content={
        <>
   
      <main>
        <Outlet /> {/* This is where nested routes will be rendered */}
      </main>
      </>
    }/>
    
  );
};

export default AppLayout;
