import http from "../../http-common";
import serviceUtil from "../../utils/service-util";  // Ensure the import path is correct

class ProjectService {
    // ------------- PROJECTS SECTION ------------- //

    /**
     * Creates a new project.
     * @param {Object} data - Project data.
     * @returns {Promise} The server response.
     */
    async newProject(data) {
        return await http.post("/projects", { "project": data });
    }

    /**
     * Updates an existing project.
     * @param {Object} data - Updated project data.
     * @returns {Promise} The server response.
     */
    async updateProject(data) {
        const id = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.patch(`/projects/${id}`, { "project": data });
    }

    /**
     * Retrieves a list of all projects.
     * @returns {Promise} The server response with the list of projects.
     */
    async getProjects() {
        return await http.get("/projects");
    }

    /**
     * Retrieves a single project by its ID.
     * @returns {Promise} The server response with the project data.
     */
    async getProject() {
        const id = serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${id}`);
    }

    // ------------- CONTACTS SECTION ------------- //

    /**
     * Creates a new contact for a project.
     * @param {Object} contactData - Contact data.
     * @returns {Promise} The server response.
     */
    async newContact(contactData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return await http.post(`/projects/${projectId}/contacts`, { "contact": contactData });
    }

    /**
     * Retrieves all contacts for a project.
     * @returns {Promise} The server response with the list of contacts.
     */
    async getContacts() {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return await http.get(`/projects/${projectId}/contacts`);
    }

    /**
     * Retrieves a single contact for a project by its ID.
     * @param {string} contactId - The ID of the contact.
     * @returns {Promise} The server response with the contact data.
     */
    async getContact(contactId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${projectId}/contacts/${contactId}`);
    }

    /**
     * Updates an existing contact for a project.
     * @param {string} contactId - The ID of the contact.
     * @param {Object} contactData - Updated contact data.
     * @returns {Promise} The server response.
     */
    async updateContact(contactId, contactData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return await http.patch(`/projects/${projectId}/contacts/${contactId}`, { "contact": contactData });
    }

    /**
     * Deletes a contact from a project.
     * @param {string} contactId - The ID of the contact.
     * @returns {Promise} The server response.
     */
    async deleteContact(contactId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return await http.delete(`/projects/${projectId}/contacts/${contactId}`);
    }

    // ------------- LINKS SECTION ------------- //

    /**
     * Creates a new link for a project.
     * @param {Object} linkData - Link data.
     * @returns {Promise} The server response.
     */
    async newLink(linkData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.post(`/projects/${projectId}/project_links`, { "project_link": linkData });
    }

    /**
     * Retrieves all links for a project.
     * @returns {Promise} The server response with the list of links.
     */
    async getLinks() {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${projectId}/project_links`);
    }

    /**
     * Retrieves a single link for a project by its ID.
     * @param {string} linkId - The ID of the link.
     * @returns {Promise} The server response with the link data.
     */
    async getLink(linkId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${projectId}/project_links/${linkId}`);
    }

    /**
     * Updates an existing link for a project.
     * @param {string} linkId - The ID of the link.
     * @param {Object} linkData - Updated link data.
     * @returns {Promise} The server response.
     */
    async updateLink(linkId, linkData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.patch(`/projects/${projectId}/project_links/${linkId}`, { "project_link": linkData });
    }

    /**
     * Deletes a link from a project.
     * @param {string} linkId - The ID of the link.
     * @returns {Promise} The server response.
     */
    async deleteLink(linkId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.delete(`/projects/${projectId}/project_links/${linkId}`);
    }

    // ------------- PHOTOS SECTION ------------- //

    /**
     * Retrieves all photos for a project.
     * @returns {Promise} The server response with the list of photos.
     */
    async getPhotos() {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${projectId}/images`);
    }

    /**
     * Uploads a new photo to a project.
     * @param {Object} photoData - Photo data.
     * @returns {Promise} The server response.
     */
    async newPhoto(photoData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        try {
            const response = await http.post(`/projects/${projectId}/images`, photoData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return response;
        } catch (error) {
            console.error('Failed to upload photo:', error);
            throw error;
        }
    }

    /**
     * Deletes a photo from a project.
     * @param {string} photoId - The ID of the photo.
     * @returns {Promise} The server response.
     */
    async deletePhoto(photoId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        try {
            const response = await http.delete(`/projects/${projectId}/images/${photoId}`);
            return response;
        } catch (error) {
            console.error('Failed to delete photo:', error);
            throw error;
        }
    }

    // ------------- FILES SECTION ------------- //

    /**
     * Retrieves all files for a project.
     * @returns {Promise} The server response with the list of files.
     */
    async getFiles() {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        return http.get(`/projects/${projectId}/files`);
    }

    /**
     * Uploads new files to a project.
     * @param {Object} fileData - File data.
     * @returns {Promise} The server response.
     */
    async newFiles(fileData) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        try {
            const response = await http.post(`/projects/${projectId}/files`, fileData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            return response;
        } catch (error) {
            console.error('Failed to upload file:', error);
            throw error;
        }
    }

    /**
     * Deletes a file from a project.
     * @param {string} fileId - The ID of the file.
     * @returns {Promise} The server response.
     */
    async deleteFile(fileId) {
        const projectId = await serviceUtil.extrapolateFinalIdFromUrl();
        try {
            const response = await http.delete(`/projects/${projectId}/files/${fileId}`);
            return response;
        } catch (error) {
            console.error('Failed to delete file:', error);
            throw error;
        }
    }

 // ------------- TICKETS SECTION ------------- //

    /**
     * Creates a new ticket for a project.
     * @param {Object} ticketData - Ticket data.
     * @returns {Promise} The server response.
     */
    async newTicket(ticketData) {
        return await http.post(`/projects/${ticketData.project_id}/tickets`, { "ticket": ticketData });
    }

    /**
     * Retrieves all tickets for a project.
     * @param {number} projectId - The ID of the project.
     * @returns {Promise} The server response with the list of tickets.
     */
    async getTickets(projectId) {
        return await http.get(`/projects/${projectId}/tickets`);
    }

    /**
     * Retrieves a single ticket by its ID.
     * @param {number} projectId - The ID of the project.
     * @param {string} ticketId - The ID of the ticket.
     * @returns {Promise} The server response with the ticket data.
     */
    async getTicket(projectId, ticketId) {
        return await http.get(`/projects/${projectId}/tickets/${ticketId}`);
    }

    /**
     * Updates an existing ticket.
     * @param {number} projectId - The ID of the project.
     * @param {string} ticketId - The ID of the ticket.
     * @param {Object} ticketData - Updated ticket data.
     * @returns {Promise} The server response.
     */
    async updateTicket(projectId, ticketId, ticketData) {
        return await http.patch(`/projects/${projectId}/tickets/${ticketId}`, { "ticket": ticketData });
    }

    /**
     * Deletes a ticket from a project.
     * @param {number} projectId - The ID of the project.
     * @param {string} ticketId - The ID of the ticket.
     * @returns {Promise} The server response.
     */
    async deleteTicket(projectId, ticketId) {
        return await http.delete(`/projects/${projectId}/tickets/${ticketId}`);
    }


// ------------- PROPOSAL TICKETS SECTION ------------- //

/**
 * Creates a new proposal ticket for a project.
 * @param {Object} proposalTicketData - Data for the new proposal ticket.
 * @returns {Promise} Promise resolving with the server response.
 */
async newProposalTicket(proposalTicketData) {
    return await http.post(`/projects/${proposalTicketData.project_id}/proposal_tickets`, { proposal_ticket: proposalTicketData });
}

/**
 * Retrieves all proposal tickets associated with a specific project.
 * @param {number} projectId - The ID of the project.
 * @returns {Promise} Promise resolving with the server response containing proposal tickets.
 */
async getProposalTickets(projectId) {
    return await http.get(`/projects/${projectId}/proposal_tickets`);
}

/**
 * Retrieves a specific proposal ticket by its ID.
 * @param {number} projectId - The ID of the project.
 * @param {string} proposalTicketId - The ID of the proposal ticket.
 * @returns {Promise} Promise resolving with the server response containing the proposal ticket data.
 */
async getProposalTicket(projectId, proposalTicketId) {
    return await http.get(`/projects/${projectId}/proposal_tickets/${proposalTicketId}`);
}

/**
 * Retrieves the history of a specific proposal ticket.
 * @param {number} projectId - The ID of the project.
 * @param {string} proposalTicketId - The ID of the proposal ticket.
 * @returns {Promise} Promise resolving with the server response containing the history of the proposal ticket.
 */
async getProposalTicketHistories(projectId, proposalTicketId) {
    return await http.get(`/projects/${projectId}/proposal_tickets/${proposalTicketId}/proposal_ticket_histories`);
}

/**
 * Updates an existing proposal ticket.
 * @param {number} projectId - The ID of the project.
 * @param {string} proposalTicketId - The ID of the proposal ticket.
 * @param {Object} proposalTicketData - Updated data for the proposal ticket.
 * @returns {Promise} Promise resolving with the server response.
 */
async updateProposalTicket(projectId, proposalTicketId, proposalTicketData) {
    return await http.patch(`/projects/${projectId}/proposal_tickets/${proposalTicketId}`, { proposal_ticket: proposalTicketData });
}

/**
 * Deletes a proposal ticket from a project.
 * @param {number} projectId - The ID of the project.
 * @param {string} proposalTicketId - The ID of the proposal ticket.
 * @returns {Promise} Promise resolving with the server response.
 */
async deleteProposalTicket(projectId, proposalTicketId) {
    return await http.delete(`/projects/${projectId}/proposal_tickets/${proposalTicketId}`);
}


}

const projectService = new ProjectService();
export default projectService;
