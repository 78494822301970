import { useState, useEffect } from 'react';
import adminService from '../../services/admin-services/admin.service';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';

export default function AdminAccountView() {
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
  const [account, setAccount] = useState({});
  const [projects, setProjects] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('Working');
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(4);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchAccountAndProjects = async () => {
      setLoading(true);
      try {
        const response = await adminService.getAccount();
        console.log(response);
  
        if (response && response.data) {
          setAccount(response.data.account || {});
          setProjects(response.data.projects || []);
        } else {
          throw new Error('Data not available');
        }
      } catch (error) {
        console.error('Failed to fetch account + projects', error);
      }
      setLoading(false);
    };
    fetchAccountAndProjects();
  }, []);
  

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
    setCurrentPage(1);
  };

  const filteredProjects = (projects || []).filter((project) => {
    if (currentFilter === 'Working') {
      return project.project_stage !== 'Archived';
    } else if (currentFilter === 'Archived') {
      return project.project_stage === 'Archived';
    }
    return true; // Ensure all projects are returned if no filter matches
  });

  const tabs = [
    { name: 'Working', current: currentFilter === 'Working', onClick: () => handleFilterChange('Working') },
    { name: 'Archived', current: currentFilter === 'Archived', onClick: () => handleFilterChange('Archived') },
  ];

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handlePreviousClick = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, totalPages));
  };

  const startResult = (currentPage - 1) * projectsPerPage + 1;
  const endResult = Math.min(startResult + projectsPerPage - 1, filteredProjects.length);

  const refetchAccount = async () => {
    try {
      const response = await adminService.getAccount();
      console.log("RESPONSE ",response);
      if (response && response.data) {
        setAccount(response.data.account || {});
      }
    } catch (error) {
      console.error("Failed to fetch account data:", error);
    }
  };

  const handleModalClose = () => {
    setIsEditAccountModalOpen(false);
    refetchAccount();
  };
  const pageContent = (
    <>
      {/* <NewProjectModal 
        isModalOpen={isNewProjectModalOpen} 
        setIsModalOpen={setIsNewProjectModalOpen}
      />
    
      <EditAccountModal
        isModalOpen={isEditAccountModalOpen}
        setIsModalOpen={setIsEditAccountModalOpen}
        account={account}
        onAccountUpdated={handleModalClose}
      /> */}

      <div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
          <nav className="sm:hidden" aria-label="Back">
            <Link to="/admin/all- accounts" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              All Accounts
            </Link>
          </nav>
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <Link to="/admin" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Admin Portal
                </Link>
              </div>
            </li>
              <li>
                <div className="flex items-center">
                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <Link to="/admin/all-accounts" className="text-sm font-medium pl-4 text-gray-500 hover:text-gray-700">
                  All Accounts
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <Link to={"/admin/accounts/"+account.id} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {account.company_name}
                  </Link>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-2">
        <div className="px-0 py-4 mt-3">
          <div>
            <div className="bg-white shadow-lg border border-gray-100 rounded-lg overflow-hidden">
              <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-semibold text-gray-900">Account Information</h2>
                    <div className="mt-3 sm:mt-0">
                      {/* <button
                        type="button"
                        onClick={() => setIsEditAccountModalOpen(true)}
                        className="inline-flex items-center rounded-md bg-gray-200 px-4 py-2 
                                  text-sm font-medium text-black hover:bg-blue-300
                                  focus:outline-none focus:ring-2 focus:ring-offset-2"
                                  
                      >
                        Edit Account
                      </button> */}
                    </div>
                  </div>
                  <div className="mt-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <dl className="sm:grid sm:grid-cols-3 sm:gap-4">
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Company</dt>
                        <dd className="mt-1 text-sm text-gray-900">{account.company_name}</dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Industry</dt>
                        <dd className="mt-1 text-sm text-gray-900">{account.industry}</dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">Account Status</dt>
                        <dd>
                          <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                              account.account_status === 'Active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                            }`}>
                              {account.account_status}
                          </span>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-lg border border-gray-100 rounded-lg overflow-hidden mb-10">
            <div className="px-4 py-5 sm:p-6">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">Projects</h3>
                <div className="">
                  {/* <button
                    onClick={() => setIsNewProjectModalOpen(true)}
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-green-400 px-5 py-2 text-sm font-semibold text-gray-900 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Project
                  </button> */}
                </div>
              </div>
              <div>
                <div className="sm:block">
                  <nav className="flex items-center space-x-2" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={tab.onClick}
                      className={`rounded-md px-3 py-2 text-sm font-medium w-24 h-10 flex justify-center items-center ${
                        tab.current ? 'bg-green-100 text-green-900' : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'
                      }`}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="mt-4">
  <div className="align-middle inline-block min-w-full">
    {loading ? (
      <div className="text-center py-4">Loading projects...</div>
    ) : (
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
              Project ID
            </th>
            <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
              Company
            </th>
            <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900 hidden sm:table-cell">
              Location
            </th>
            <th scope="col" className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {currentProjects.length > 0 ? (
            currentProjects.map((project) => (
              <tr key={project.id} className="even:bg-gray-50">
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                  {project.id}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate">
                  {project.company_name}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                  {project.industry}
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ${
                    project.project_stage === 'Archived' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                  }`}>
                    {project.project_stage}
                  </span>
                </td>
                <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500">
                  <Link to={"/admin/projects/"+project.id} className="text-blue-500 hover:text-blue-700">
                    View
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500">
                No Projects Yet
              </td>
            </tr>
          )}
          { [...Array(Math.max(5 - currentProjects.length, 0))].map((_, index) => (
            <tr key={`empty-${index}`} className="even:bg-gray-50">
              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 truncate"></td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell"></td>
              <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-500"></td>
              <td className="px-3 py-4 whitespace-nowrap text-md text-gray-500"></td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
</div>



            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{startResult}</span> to <span className="font-medium">{endResult}</span> of{' '}
                  <span className="font-medium">{filteredProjects.length}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  onClick={handlePreviousClick}
                  className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </button>
                <button
                  onClick={handleNextClick}
                  className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
  return pageContent;
}









