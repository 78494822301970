import React from 'react';

// Array containing descriptions and details for each photo
const photoDescriptions = [
    {
        img: "images/Picture2.jpg",
        customer: "Sevita Health",
        status: "COMPLETED PROJECT",
        city: "Northridge, CA",
        systemSize: "20.8 KW + 60 kWh Battery Storage",
        completionDate: "04 / 2024"
    },
    {
        img: "images/Picture3.jpg",
        customer: "Christian Missionary Alliance",
        status: "COMPLETED PROJECT",
        city: "Redlands, CA",
        systemSize: "17.60 kW",
        completionDate: "05 / 2023"
    },
    {
        img: "images/Picture4.jpg",
        customer: "Zoe Center",
        status: "COMPLETED PROJECT",
        city: "Whittier, CA",
        systemSize: "80.28 kW",
        completionDate: "04 / 2023"
    },
    {
        img: "images/Picture5.jpg",
        customer: "Freedom Firearms & Defense",
        status: "Project Under Contract",
        city: "Beaumont, TX",
        systemSize: "149.52 kW",
        completionDate: "Estimated Project Completion: 06 / 2024"
    },
    {
        img: "images/Picture6.jpg",
        customer: "99 Cents & More",
        status: "Project Under Contract",
        city: "Arbuckle, CA",
        systemSize: "45.6 kW + 61.44 kWh Battery Storage",
        completionDate: "Estimated Project Completion: 07 / 2024"
    },
    {
        img: "images/Picture7.jpg",
        customer: "Corner Bakery",
        status: "Project Under Contract",
        city: "Arbuckle, CA",
        systemSize: "40.08 kW + 61.44 kWh Battery Storage",
        completionDate: "Estimated Completion Date: 07 / 2024"
    },
    {
        img: "images/Picture8.jpg",
        customer: "Sevita Health",
        status: "Project Under Negotiation",
        city: "La Mesa, CA",
        systemSize: "62.4 kW + 157.74 kWh Battery Storage",
        completionDate: "Estimated Completion Date: 08 / 2024"
    },
    {
        img: "images/Picture9.jpg",
        customer: "Sevita Health",
        status: "Project Under Negotiation",
        city: "Santa Ana, CA",
        systemSize: "61.92 kW + 128.88 kWh Battery Storage",
        completionDate: "Estimated Completion Date: 09 / 2024"
    }
];

/**
 * CommercialSolarPage component renders a gallery of commercial solar projects.
 * Each project includes an image and details such as customer, status, city, system size, and completion date.
 *
 * @returns {JSX.Element} The CommercialSolarPage component.
 */
export default function CommercialSolarPage() {
    return (
        <div className="py-10 px-6 mb-12">
            {/* Page header */}
            <header className="text-center">
                <h1 className="text-4xl font-bold text-gray-900">Commercial Solar Project Gallery</h1>
                <p className="mt-4 text-lg text-gray-600">Explore our portfolio of commercial solar projects showcasing our commitment to sustainability and innovative energy solutions.</p>
            </header>

            {/* Gallery section */}
            <section className="mt-10">
                <div className="border border-gray-100 shadow-xl rounded-2xl p-4 mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {photoDescriptions.map((photo, index) => (
                        <div key={index} className="shadow-xl bg-white border border-gray-100 p-6 rounded-lg hover:shadow-xl transition-shadow duration-300">
                            {/* Project image */}
                            <img src={photo.img} alt={photo.customer} className="w-full h-auto max-w-full max-h-80 object-cover rounded-lg mb-6"/>
                            {/* Project details */}
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">{photo.customer}</h3>
                            <ul className="list-disc pl-5 text-gray-600 space-y-1">
                                <li><span className="font-semibold">Status:</span> {photo.status}</li>
                                <li><span className="font-semibold">City:</span> {photo.city}</li>
                                <li><span className="font-semibold">System Size:</span> {photo.systemSize}</li>
                                <li><span className="font-semibold">Completion Date:</span> {photo.completionDate}</li>
                            </ul>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}
